import { IoCloudUploadOutline } from "react-icons/io5";
import { TiDeleteOutline } from "react-icons/ti";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { MdEdit } from "react-icons/md";
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "@/components/ui/avatar"
import { useLanguage } from "./context/LanguageContext";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "./ui/button";
import LoadingScreen from "./loading/loading-screen";
import { users } from "@/services/user-service";
import { Label } from "./ui/label";
import FemalIcon from "../assets/female-icon.png";
import { ScrollArea } from "./ui/scroll-area";

const UpdateProfilePhotoComponent = (
    { profile }: { profile: any }
) => {
    const { language } = useLanguage();
    const [newPhoto, setNewPhoto] = useState<any>();
    const [formLoading, setFormLoading] = useState(false);

    const onDrop = (acceptedFiles: any) => {
        console.log(acceptedFiles[0]);
        setNewPhoto(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/jpeg': ['.jpg', '.jpeg'],
            'image/png': ['.png']
        },
        multiple: false
    });

    const handleUpdatePhoto = async () => {
        if (newPhoto) {
            setFormLoading(true);
            const fileObject = newPhoto?.file || newPhoto;
            let data = new FormData();
            data.append("photo", fileObject, fileObject.name);
            await users.update_profile_photo(data).then((response: any) => {
                setFormLoading(false);
                location.reload();
            }).catch((error: any) => {
                setFormLoading(false);
            })
        }
    }


    const handleUpdateToFemaleIcon = async () => {
        const response = await fetch(FemalIcon);
        const blob = await response.blob();

        const file = new File([blob], "female-icon.png", { type: blob.type });

        let data = new FormData();
        data.append("photo", file, file.name);

        await users.update_profile_photo(data)
            .then(() => {
                setFormLoading(false);
                location.reload();
            })
            .catch((error: any) => {
                setFormLoading(false);
            });
    }

    return (
        <div
            className="flex flex-col items-center mt-6"
        >
            <Dialog>
                <DialogTrigger asChild>
                    <Button
                        className="w-fit ml-[140px] mb-[-18px]"
                        variant={"ghost"}
                    >
                        <MdEdit size={24} />
                    </Button>
                </DialogTrigger>
                <DialogContent
                    dir={language === 'en' ? 'ltr' : 'rtl'}
                >
                    <DialogHeader
                        className="m-0"
                    >
                        <DialogTitle
                            className="text-center text-lg"
                        >
                            {language === 'en' ? 'Change Profile Picture' : 'تغيير الصورة الشخصية'}
                        </DialogTitle>
                    </DialogHeader>
                    <ScrollArea
                        className="max-h-[70vh] p-4"
                    >
                        <DialogDescription>
                            <ul
                                className="text-sm text-center flex flex-col gap-2 text-start mt-4"
                            >
                                <li>

                                    {language === 'en' ? 'Accepted file types: .jpg, .jpeg, .png' : 'الأنواع المقبولة: .jpg, .jpeg, .png'}
                                </li>
                                <li>

                                    {language === 'en' ? 'Recommended size: 250x300 pixels' : 'الحجم الموصى به: 250x300 بيكسل'}
                                </li>
                                <li>

                                    {language === 'en' ? 'Avoid using photos with a lot of details' : 'تجنب استخدام صور تحتوي على العديد من التفاصيل'}
                                </li>
                                <li>

                                    {language === 'en' ? 'Avoid using an image taken with a filter' : 'تجنب استخدام صورة ملتقطة بفلتر'}
                                </li>
                                <li>

                                    {language === 'en' ? 'Avoid using a photo with a busy background' : 'تجنب استخدام صورة تحتوي على خلفية مزدحمة'}
                                </li>
                                <li>

                                    {language === 'en' ? 'You can remove the background of the photo using this site:' : 'يمكنك إزالة خلفية الصورة باستخدام هذا الموقع:'}
                                </li>
                                <a
                                    href="https://www.remove.bg/"
                                    target="_blank"
                                    className="text-blue-500"
                                >
                                    {language === 'en' ? 'Remove.bg' : 'Remove.bg'}
                                </a>
                            </ul>
                            {/*<!-- Component: Dropzone file input --> */}
                            <div className="relative">
                                <Label className="mb-2">
                                    {language === 'en' ? 'Photo' : 'الصورة'}
                                </Label>
                                <div
                                    {...getRootProps({
                                        className:
                                            "relative flex cursor-pointer flex-col items-center  rounded border border-dashed border-slate-300 px-3 py-6 text-center text-sm font-medium transition-colors",
                                    })}
                                >
                                    <input
                                        {...getInputProps()}
                                        multiple={false}
                                    />
                                    <IoCloudUploadOutline
                                        className="inline-flex h-12 items-center justify-center self-center"
                                        size={24}
                                    />
                                    <span className="text-slate-500">
                                        {language === "en"
                                            ? "Drag or click to upload a photo"
                                            : "اسحب أو انقر لتحميل صورة"}
                                    </span>
                                </div>
                            </div>
                            {newPhoto && (
                                <div className="">
                                    <div className="flex gap-2 items-center justify-between my-2">
                                        <TiDeleteOutline
                                            onClick={() => {
                                                setNewPhoto(null);
                                            }}
                                            className="text-red-500 cursor-pointer"
                                            size={24}
                                        />
                                    </div>
                                    <div
                                        className="mx-auto"
                                        style={{
                                            objectFit: 'cover',
                                            backgroundImage: `url(${URL.createObjectURL(newPhoto)})`,
                                            backgroundSize: 'cover',
                                            width: '250px',
                                            height: '300px',
                                            backgroundPosition: 'center'
                                        }}
                                    >
                                        {/* <img
                                    src={URL.createObjectURL(newPhoto.staff_profile.photo)}
                                    alt="new photo"
                                    className="w-[300px] h-[350px] bg-cover bg-center"
                                /> */}
                                    </div>
                                </div>
                            )}
                        </DialogDescription>
                    </ScrollArea>

                    {/*<!-- End Dropzone file input --> */}
                    <DialogFooter>
                        <div
                            className="flex flex-col items-center gap-4 w-full"
                        >
                            <Button
                                onClick={() => handleUpdatePhoto()}
                                className="w-full"
                                disabled={!newPhoto}
                            >
                                {language === 'en' ? 'Save' : 'حفظ'}
                                {formLoading && <LoadingScreen />}
                            </Button>

                            {
                                (profile?.profile_user?.gender === 'Female') &&
                                <Button
                                    onClick={() => handleUpdateToFemaleIcon()}
                                    className="w-full"
                                    variant={'destructive'}
                                >
                                    {language === 'en' ? 'Change to female icon' : 'تغيير إلى أيقونة أنثى'}
                                    {formLoading && <LoadingScreen />}
                                </Button>
                            }
                        </div>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <Avatar
                className="w-[150px] h-[150px] relative mx-auto"
            >
                <AvatarImage
                    src={profile.staff_profile?.photo}
                    alt="profile image"
                    className="w-full h-full bg-cover bg-center"
                />
                <AvatarFallback>
                    {profile.first_name.charAt(0)}{profile.last_name.charAt(0)}
                </AvatarFallback>
            </Avatar>
        </div>
    );
}

export default UpdateProfilePhotoComponent;