import React, { forwardRef, useEffect, useState } from 'react';
import Logo from '@/assets/Lazem-logo.webp';
import footer from '@/assets/card-footer-image.png';
import femaleIcon from '@/assets/female-icon.png';
import "./card-template.css";

const EmployeeCardPrintTemplate = forwardRef<HTMLDivElement, any>((
    { id, image, ar_full_name, en_full_name, ar_role, en_role, gender, ...props },
    ref
) => {
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    useEffect(() => {
        console.log('Image:', image, typeof image);
        fetchImage();
    }, [image]);

    const fetchImage = () => {
        if (!gender) {
            // If gender is not provided, use the logo as the default image
            setImageUrl(Logo);
        } else if (!image) {
            // If no image is provided, fallback based on gender
            setImageUrl(gender === "Male" ? Logo : femaleIcon);
        } else if (image instanceof File || image instanceof Blob) {
            // If the image is a File or Blob, create an object URL
            setImageUrl(URL.createObjectURL(image));
        } else if (typeof image === "string") {
            // If the image is a string, assume it's a valid URL
            setImageUrl(image);
        } else {
            // For any unexpected types, log a warning and fallback to the logo
            console.warn("Unexpected image type:", image);
            setImageUrl(Logo);
        }
    };
    

    const countLines = (id: string) => {
        const el = document.getElementById(`${id}t`);
        return el ? el.offsetHeight : 0;
    };


    return (
        <div
            ref={ref}
            id={'component-to-print'}
            className="w-[500px] h-auto m-12 mx-auto border-solid border-2 overflow-hidden bg-white"
            {...props}
        >
            <div 
                className="flex m-auto justify-center w-[110px] mx-auto items-center my-2"
            >
                <img 
                    src={Logo} 
                    alt="logo" 
                    className="w-[110px] h-[110px]"  
                />
            </div>
            <div
                className="flex justify-center m-auto items-center w-[250px] h-[300px] mx-auto border-solid border-2 border-[#274d5b] rounded-lg"
                style={{
                    backgroundImage: `url(${imageUrl || Logo})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            />
            <div className="mx-auto">
                <div className="text-center text-2xl my-2 font-bold">
                    <h2 className="text-[#274d5b]">{ar_full_name}</h2>
                    <h2 className="text-[#ea0d44]">{en_full_name}</h2>
                </div>
                <div
                    id={`${id || ''}t`}
                    className={`text-center text-xl ${countLines(id) > 75 ? 'daiit' : ''}`}
                >
                    <h3 className="text-[#274d5b]">{ar_role}</h3>
                    <h3 className="text-[#ea0d44] capitalize">{en_role}</h3>
                </div>
                <div className="text-center mt-2 text-xl">
                    <p className="text-[#274d5b]">
                        Employee ID
                    </p>
                    <p className="text-[#ea0d44]">{id}</p>
                </div>
            </div>
            <div className="mt-[20px] w-full">
                <img src={footer} 
                    alt="footer" 
                    className="w-full"
                />
            </div>
        </div>
    );
});

export default EmployeeCardPrintTemplate;
