import { useLanguage } from "@/components/context/LanguageContext";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { departments } from "@/services/project-service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
} from "@/components/ui/select";
import { roles } from "@/services/user-service";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { MdDeleteForever } from "react-icons/md";
import { useAuth } from "@/components/context/AuthContext";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { is_arabic, is_english } from "@/services/language-checker";



const EditDepartment = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { language } = useLanguage();
  const [updatedFields, setUpdatedFields] = useState<any>();
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const queryClient = useQueryClient();
  const [isDepartmentEdit, setIsDepartmentEdit] = useState(false);
  const [addedRoles, setAddedRoles] = useState<any>({
    title: "",
    title_ar: "",
    position: "",
    description: "",
    department: id,
  });
  const [postionChoices] = useState<any>([
    "manager",
    "staff",
  ]);
  const [isRoleLoading, setIsRoleLoading] = useState(false);
  const [isAddingRole, setIsAddingRole] = useState(false);
  const { checkPermission } = useAuth();
  const canViewRoles = checkPermission('view_roles');
  const [isEditDepartmentRole, setIsEditDepartmentRole] = useState(false);
  const [editedRoles, setEditedRoles] = useState<Record<string, any>>({});
  const [roleFormError, setRoleFormError] = useState("");

  useEffect(() => {
    document.title = "Lazem GO - Edit Department";
  }, []);

  const {
    data: department,
    isLoading: departmentLoading,
    isFetching: departmentFetching,
    refetch: departmentRefetching,
  } = useQuery({
    queryKey: ["edit department"],
    queryFn: () => {
      return departments.get(id).then((response) => {
        return response;
      });
    },
    refetchOnWindowFocus: false,
  });


  const checkUpdatedRoleFields = (role: any) => {
    if (role?.title === "" || role?.position === "" || role?.title_ar === "") {
      setRoleFormError(language === "en" ? "Title and Position are required" : "العنوان والمنصب مطلوبان");
      return false;
    }
    else if (role?.title && !is_english(role?.title)) {
      setRoleFormError(language === 'en' ? 'English fields should contain only English characters' : 'حقول الانجليزية يجب أن تحتوي على حروف انجليزية فقط');
      return false;
    }
    else if (role?.title_ar && !is_arabic(role?.title_ar)) {
      setRoleFormError(language === "en" ? "Arabic fields should contain only Arabic characters" : "حقول العربية يجب أن تحتوي على حروف عربية فقط");
      return false;
    }
    else {
      setRoleFormError("");
      return true;
    }
  }

  const handleRoleUpdate = (roleId: string) => {
    if (editedRoles[roleId] && checkUpdatedRoleFields(editedRoles[roleId])) {
      roles
        .update(roleId, editedRoles[roleId])
        .then(() => {
          departmentRefetching();
          setEditedRoles((prev) => {
            const updated = { ...prev };
            delete updated[roleId];
            return updated;
          });
        })
        .catch((error) => {
          console.error("Error updating role:", error);
        });
    }
  };

  const handleFieldChange = (roleId: string, field: string, value: any) => {
    setEditedRoles((prev) => ({
      ...prev,
      [roleId]: {
        ...prev[roleId],
        [field]: value,
      },
    }));
  };

  const checkForm = () => {
    if (department?.data.title === "") {
      setFormError(language === "en" ? "Title is required" : "العنوان مطلوب");
      return false;
    } 
    else if (!is_english(department?.data.title)) {
      setFormError(language === 'en' ? 'English fields should contain only English characters' : 'حقول الانجليزية يجب أن تحتوي على حروف انجليزية فقط');
      return false;
    }
    else if (!is_arabic(department?.data.title_ar)) {
      setFormError(language === "en" ? "Arabic fields should contain only Arabic characters" : "حقول العربية يجب أن تحتوي على حروف عربية فقط");
      return false;
    }
    else if (department?.data.title_ar === "") {
      setFormError(language === "en" ? "Title (Arabic) is required" : "العنوان (العربية) مطلوب");
      return false;
    }
    else if (!updatedFields) {
      setFormError(
        language === "en" ? "No changes detected" : "لا توجد تغييرات"
      );
      return false;
    }
    return true;
  };

  const handleUpdate = async () => {
    if (checkForm()) {
      setFormLoading(true);
      await departments.update(id, updatedFields)
        .then(() => {
          setFormError("");
          setUpdatedFields({});
          setFormLoading(false);
          setIsDepartmentEdit(false);
          departmentRefetching();
        })
        .catch((error) => {
          setFormLoading(false);
          console.log("Error", error);
        });
    }
  };

  const handelDeleteDepartment = () => {
    setFormLoading(true);
    departments.delete(id)
      .then(() => {
        navigate("/organization/department");
      })
      .catch((error) => {
        console.log("Error", error);
      });
    setFormLoading(false);
  }

  const checkRoleForm = () => {
    if (addedRoles.title === "") {
      setFormError(language === "en" ? "Title is required" : "العنوان مطلوب");
      return false;
    } else if (addedRoles.position === "") {
      setFormError(language === "en" ? "Position is required" : "المنصب مطلوب");
      return false;
    }
    else if (!is_english(addedRoles.title)) {
      setFormError(language === 'en' ? 'English fields should contain only English characters' : 'حقول الانجليزية يجب أن تحتوي على حروف انجليزية فقط');
      return false;
    }
    else if (!is_arabic(addedRoles.title_ar)) {
      setFormError(language === "en" ? "Arabic fields should contain only Arabic characters" : "حقول العربية يجب أن تحتوي على حروف عربية فقط");
      return false;
    }
    else if (addedRoles.title_ar === "") {
      setFormError(language === "en" ? "Title (Arabic) is required" : "العنوان (العربية) مطلوب");
      return false;
    }
    return true;
  };

  const deleteRole = (id: string) => {
    roles
      .update(id, { is_active: false })
      .then(() => {
        departmentRefetching();
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };


  const handleAddRole = () => {
    if (checkRoleForm()) {
      setFormError("");
      setIsRoleLoading(true);
      roles
        .create(addedRoles)
        .then(() => {
          departmentRefetching();
          setAddedRoles({
            title: "",
            title_ar: "",
            position: "",
            description: "",
            department: id,
          });
        })
        .catch((error) => {
          console.log("Error", error);
        })
        .finally(() => {
          setIsAddingRole(false);
          setIsRoleLoading(false);
        });
    }
  };

  const departmentRolesComponent = () => {
    return (
      <>
        <div className="flex justify-between items-center">
          <Button
            onClick={() => {
              setIsEditDepartmentRole(!isEditDepartmentRole);
            }}
            variant={isEditDepartmentRole ? "destructive" : "secondary"}
            permission="change_roles"
          >
            {isEditDepartmentRole
              ? language === "en"
                ? "Cancel"
                : "إلغاء"
              : language === "en"
                ? "Edit"
                : "تعديل"}
          </Button>
        </div>

        {roleFormError && 
          <div className="text-red-500">
            {roleFormError}
          </div>
        }

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead
                className="text-start"
              >
                {language === "en" ? "Position" : "المنصب"}
              </TableHead>
              <TableHead

                className="text-start"
              >
                {language === "en" ? "Role title (English)" : "المسمى الوظيفي (الإنجليزية)"}
              </TableHead>
              <TableHead
                className="text-start"
              >
                {language === "en" ? "Role title (Arabic)" : "المسمى الوظيفي (العربية)"}
              </TableHead>
              <TableHead
                className="text-start"
              >
                {language === "en" ? "Description" : "الوصف"}
              </TableHead>
              <TableHead
                className="text-start"
              >
                {language === "en" ? "Code Name" : "الرمز"}
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {department?.data?.roles?.map((role: any) => (
              <TableRow>
                <TableCell>
                  <Select
                    defaultValue={role?.position}
                    onValueChange={(value) => handleFieldChange(role.id, "position", value)}
                    disabled={!isEditDepartmentRole}
                  >
                    <SelectTrigger>
                      <SelectValue
                        placeholder={
                          language === "en" ? "Position" : "المنصب"
                        }
                      />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>
                          {language === "en" ? "Position" : "المنصب"}
                        </SelectLabel>
                        {postionChoices.map((position: any) => (
                          <SelectItem key={position} value={position}>
                            {position}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </TableCell>
                <TableCell>
                  <Input
                    defaultValue={role?.title}
                    onChange={(e) => handleFieldChange(role.id, "title", e.target.value)}
                    disabled={!isEditDepartmentRole}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    defaultValue={role?.title_ar}
                    onChange={(e) => handleFieldChange(role.id, "title_ar", e.target.value)}
                    disabled={!isEditDepartmentRole}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    defaultValue={role?.description}
                    onChange={(e) => handleFieldChange(role.id, "description", e.target.value)}
                    disabled={!isEditDepartmentRole}
                  />
                </TableCell>
                <TableCell>
                  <Input
                    defaultValue={role?.code}
                    onChange={(e) => handleFieldChange(role.id, "code", e.target.value)}
                    disabled={!isEditDepartmentRole}
                  />
                </TableCell>
                <TableCell
                  className="flex justify-between gap-2"
                >
                  <Button
                    onClick={() => handleRoleUpdate(role.id)}
                    disabled={!editedRoles[role.id]}
                  >
                    {language === "en" ? "Update" : "تحديث"}
                  </Button>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        variant={"secondary"}
                        className="w-fit"
                        permission="delete_roles"
                      >
                        <MdDeleteForever className="text-red-500 cursor-pointer" size={30} />
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="sm:max-w-[425px]">
                      <DialogHeader>
                        <DialogTitle className="text-center">
                          {language === "en" ? (
                            <>
                              Are you sure you want to delete
                              <br />
                              <br />
                              {role.title}?
                            </>
                          ) : (
                            <>
                              هل أنت متأكد أنك تريد حذف
                              <br />
                              <br />
                              {role.title}؟
                            </>
                          )}
                        </DialogTitle>
                      </DialogHeader>

                      <DialogFooter>
                        <Button
                          type="submit"
                          variant="destructive"
                          className="m-auto"
                          disabled={formLoading || departmentLoading}
                          onClick={() => {
                            deleteRole(role.id);
                          }}
                          permission="delete_roles"
                        >
                          {language === "en" ? "Delete" : "حذف"}
                        </Button>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {
          setAddRoleComponent()
        }

      </>
    );
  };

  const setAddRoleComponent = () => {
    return (
      <Card className="text-start mt-8 p-2">
        {isAddingRole ? (
          <Button
            onClick={() => {
              setIsAddingRole(false);
            }}
            variant={"destructive"}
            className="w-fit mx-auto flex"
            permission="add_roles"
          >
            {language === "en" ? "Cancel" : "إلغاء"}
          </Button>
        ) : (
          <Button
            onClick={() => {
              setIsAddingRole(true);
            }}
            variant={"secondary"}
            className="w-fit mx-auto flex"
            permission="add_roles"
          >
            {language === "en" ? "Add Role" : "إضافة دور"}
          </Button>
        )}
        {isAddingRole && (
          <>
            <div className="my-4">
              <Label className="mb-4">
                {language === "en" ? "Role title (English)" : "المسمى الوظيفي (الإنجليزية)"}
              </Label>
              <Input
                value={addedRoles.title}
                onChange={(e) => {
                  setAddedRoles({
                    ...addedRoles,
                    title: e.target.value,
                  });
                }}
              />
            </div>
            <div className="my-4">
              <Label className="mb-4">
                {language === "en" ? "Role title (Arabic)" : "المسمى الوظيفي (العربية)"}
              </Label>
              <Input
                value={addedRoles.title_ar}
                onChange={(e) => {
                  setAddedRoles({
                    ...addedRoles,
                    title_ar: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mb-4">
              <Label className="mb-4">
                {language === "en" ? "Description" : "الوصف"}
              </Label>
              <Input
                value={addedRoles.description}
                onChange={(e) => {
                  setAddedRoles({
                    ...addedRoles,
                    description: e.target.value,
                  });
                }}
              />
            </div>
            <div className="mb-4">
              <Label className="mb-4">
                {language === "en" ? "Position" : "المنصب"}
              </Label>
              <Select
                value={addedRoles.position}
                onValueChange={(value) => {
                  setAddedRoles({
                    ...addedRoles,
                    position: value,
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue>{addedRoles.position}</SelectValue>
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectLabel>
                      {language === "en" ? "Position" : "المنصب"}
                    </SelectLabel>
                    {postionChoices.map((position: any) => (
                      <SelectItem key={position} value={position}>
                        {position}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <div className="my-4">
              <Label className="mb-4">
                {language === "en" ? "Code Name" : "الرمز"}
              </Label>
              <Input
                value={addedRoles?.code}
                onChange={(e) => {
                  setAddedRoles({
                    ...addedRoles,
                    code: e.target.value,
                  });
                }}
              />
            </div>
            { (formError && isAddingRole) &&

              <div className="mb-4 text-red-500">{formError}</div>
            }
            <div className="flex justify-end">
              <Button
                onClick={handleAddRole}
                className="w-full"
                disabled={isRoleLoading}
                permission="add_roles"
              >
                {isRoleLoading ? (
                  <>
                    <LoadingIcon />
                  </>
                ) : language === "en" ? (
                  "Add Role"
                ) : (
                  "إضافة دور"
                )}
              </Button>
            </div>
          </>
        )}
      </Card>
    );
  };

  return (
    <div
      className="flex flex-col items-center justify-center h-full"
      dir={language === "en" ? "ltr" : "rtl"}
    >
      {(departmentLoading || departmentFetching) && <LoadingScreen />}
      {
        <>
          <Card
            className="w-full md:w-4/5 lg:w-4/5"
          >
            <CardHeader>
              <Button
                onClick={() => navigate("/organization/department")}
                className="w-full my-4"
                variant={"secondary"}
              >
                {language === "en" ? "Back" : "عودة"}
              </Button>
              <CardTitle>
                {language === "en" ? "Edit Department" : "تعديل القسم"}
              </CardTitle>
              <div
                className={`flex justify-between items-center `}
              >
                {isDepartmentEdit ? (
                  <Button
                    onClick={() => {
                      setIsDepartmentEdit(false);
                    }}
                    variant={"destructive"}
                    className="w-fit"
                    permission="change_departments"
                  >
                    {language === "en" ? "Cancel" : "إلغاء"}
                  </Button>
                ) : (
                  <Button
                    onClick={() => {
                      setIsDepartmentEdit(true);
                    }}
                    variant={"secondary"}
                    className="w-fit"
                    permission="change_departments"
                  >
                    {language === "en" ? "Edit" : "تعديل"}
                  </Button>
                )}

                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant={"destructive"}
                      className="w-fit"
                      permission="delete_departments"
                    >
                      {language === "en" ? "Delete" : "حذف"}
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                      <DialogTitle className="text-center">
                        {language === "en" ? (
                          <>
                            Are you sure you want to delete
                            <br />
                            <br />
                            {department?.data.title}?
                          </>
                        ) : (
                          <>
                            هل أنت متأكد أنك تريد حذف
                            <br />
                            <br />
                            {department?.data.title}؟
                          </>
                        )}
                      </DialogTitle>
                    </DialogHeader>

                    <DialogFooter>
                      <Button
                        type="submit"
                        variant="destructive"
                        className="m-auto"
                        disabled={formLoading || departmentLoading}
                        onClick={() => {
                          handelDeleteDepartment();
                        }}
                      >
                        {language === "en" ? "Delete" : "حذف"}
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>

            </CardHeader>
            <CardContent className="text-start">
              <div className="">
                <div className="mb-4">
                  <Label className="mb-4">
                    {language === "en" ? "Title (English)" : "العنوان (الإنجليزية)"}
                  </Label>
                  <Input
                    value={department?.data.title}
                    disabled={!isDepartmentEdit}
                    onChange={(e) => {
                      queryClient.setQueryData(
                        ["edit department"],
                        (oldData: any) => {
                          return {
                            ...oldData,
                            data: {
                              ...oldData.data,
                              title: e.target.value,
                            },
                          };
                        }
                      );
                      setUpdatedFields({
                        ...department,
                        title: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mb-4">
                  <Label className="mb-4">
                    {language === "en" ? "Title (Arabic)" : "العنوان (العربية)"}
                  </Label>
                  <Input
                    value={department?.data.title_ar}
                    disabled={!isDepartmentEdit}
                    onChange={(e) => {
                      queryClient.setQueryData(
                        ["edit department"],
                        (oldData: any) => {
                          return {
                            ...oldData,
                            data: {
                              ...oldData.data,
                              title_ar: e.target.value,
                            },
                          };
                        }
                      );
                      setUpdatedFields({
                        ...department,
                        title_ar: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mb-4">
                  <Label className="mb-4">
                    {language === "en" ? "Description" : "الوصف"}
                  </Label>
                  <Input
                    value={department?.data.description}
                    disabled={!isDepartmentEdit}
                    onChange={(e) => {
                      queryClient.setQueryData(
                        ["edit department"],
                        (oldData: any) => {
                          return {
                            ...oldData,
                            data: {
                              ...oldData.data,
                              description: e.target.value,
                            },
                          };
                        }
                      );
                      setUpdatedFields({
                        ...department,
                        description: e.target.value,
                      });
                    }}
                  />
                </div>
                {isDepartmentEdit && (
                  <>
                    {formError !== "" ? (
                      <div className="mb-4 text-red-500">{formError}</div>
                    ) : null}
                    <div className="flex justify-end">
                      <Button
                        onClick={handleUpdate}
                        disabled={formLoading}
                        className="w-full "
                        permission="change_departments"
                      >
                        {formLoading ? (
                          <>
                            <LoadingIcon />
                          </>
                        ) : language === "en" ? (
                          "Update"
                        ) : (
                          "تحديث"
                        )}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </CardContent>

            {canViewRoles &&

              <Card className="w-full border-none">
                <CardHeader>
                  <CardTitle>{language === "en" ? "Roles" : "الأدوار"}</CardTitle>
                </CardHeader>
                <CardContent>{departmentRolesComponent()}</CardContent>
              </Card>
            }
          </Card>
        </>
      }
    </div>
  );
};

export default EditDepartment;
