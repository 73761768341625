import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Logs } from "@/services/log-services";
import LoadingScreen from "@/components/loading/loading-screen";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectGroup,
  SelectLabel,
  SelectItem,
} from "@/components/ui/select";
import PaginationComponent from "@/components/pagination-component";
import { useLanguage } from "@/components/context/LanguageContext";

const FilterOptions = ["page", "status_code", "method", "api", "client_ip_address"];

const SystemLogsDashboard = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { language } = useLanguage();

  const [filter, setFilter] = useState(searchParams.toString() || "page=1");
  const [selectedFilter, setSelectedFilter] = useState(FilterOptions[0]);
  const [pageCount, setPageCount] = useState(0);

  const {
    data: logsData,
    isLoading,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["logs", filter],
    queryFn: () =>
      Logs.list(filter).then((response) => {
        if (response?.next){
          setPageCount(Math.ceil(response?.count / response?.results?.length));
        }
        else{
          setPageCount(Number(searchParams.get("page")));
        }
        return response;
      }),
    refetchOnWindowFocus: false,
    enabled: Boolean(filter),
  });

  useEffect(() => {
    setFilter(searchParams.toString() || "page=1");
  }, [searchParams]);

  const handleSearch = (value: string) => {
    searchParams.set("page", "1");
    searchParams.set(selectedFilter, value);
    navigate(`?${searchParams.toString()}`);
    setFilter(searchParams.toString());
  };

  const handlePageChange = (page:any) => {
    searchParams.set("page", page);
    navigate(`?${searchParams.toString()}`);
    setFilter(searchParams.toString());
  };

  const formatDate = (isoDate: string) => {
    return new Date(isoDate).toLocaleDateString("en-SA", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });
  };

  const getStatusCellStyle = (status: number) => {
    const statusClass :any = {
      2: "bg-green-200 dark:bg-green-700 text-black dark:text-white",
      3: "bg-yellow-200 dark:bg-yellow-700",
      4: "bg-red-200 dark:bg-red-700",
      5: "bg-red-200 dark:bg-red-700",
    };
    const group = Math.floor(status / 100);
    return statusClass[group] || "";
  };

  const renderTableRows = (logs: any[]) => {
    return logs.map((log) => (
      <tr key={log.id} className="odd:bg-white even:bg-gray-50 dark:odd:bg-gray-900 dark:even:bg-gray-800">
        <td className="px-6 py-4">{log.id}</td>
        <td className="px-6 py-4">{formatDate(log.added_on)}</td>
        <td className="px-6 py-4">{log.api}</td>
        <td className="px-6 py-4">{log.method}</td>
        <td className="px-6 py-4">{log.client_ip_address}</td>
        <td className={`px-6 py-4 ${getStatusCellStyle(log.status_code)}`}>{log.status_code}</td>
        <td className="px-6 py-4">{log.execution_time}</td>
      </tr>
    ));
  };

  return (
    <div>
      {(isLoading || isFetching) && <LoadingScreen />}
      <h1 className="text-3xl font-bold text-center my-4">
        {language === "en" ? "System Logs" : "سجلات النظام"}
      </h1>

      <div className="flex justify-center items-center my-4 w-2/5 mx-auto">
        <Select value={selectedFilter} onValueChange={setSelectedFilter}>
          <SelectTrigger>
            <SelectValue>{selectedFilter}</SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectLabel>{language === "en" ? "Filters" : "فلتر"}</SelectLabel>
              {FilterOptions.map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
        <Input
          type="text"
          placeholder={language === "en" ? "Search" : "بحث"}
          onKeyPress={(e) => e.key === "Enter" && handleSearch(e.currentTarget.value)}
        />
      </div>

      <div className="relative shadow-md sm:rounded-lg max-w-full overflow-x-auto">
        <table className="w-full text-sm text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-white">
            <tr>
              <th className="px-6 py-3">Id</th>
              <th className="px-6 py-3">Added On</th>
              <th className="px-6 py-3">API</th>
              <th className="px-6 py-3">Method</th>
              <th className="px-6 py-3">Client IP</th>
              <th className="px-6 py-3">Status Code</th>
              <th className="px-6 py-3">Execution Time</th>
            </tr>
          </thead>
          <tbody>{logsData?.results && renderTableRows(logsData.results)}</tbody>
        </table>
      </div>

      {logsData && (
        <PaginationComponent
          currentPage={Number(searchParams.get("page")) || 1}
          totalPages={pageCount}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default SystemLogsDashboard;
