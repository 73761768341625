import { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import LoadingIcon from "@/components/loading/loading-icon";
import { useNavigate} from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Card, CardContent } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import { tickets, categories } from "@/services/tickets-services";

const OffboardingNotRegisterUser = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const [listOfReasons] = useState([
        {
            title_en: "End of contract",
            title_ar: "نهاية العقد",
        },
        {
            title_en: "Resignation",
            title_ar: "استقالة",
        },
        {
            title_en: "Retirement",
            title_ar: "تقاعد",
        },
        {
            title_en: "Layoff",
            title_ar: "فصل",
        },
        {
            title_en: "Termination for Cause",
            title_ar: "إنهاء لسبب",
        },
        {
            title_en: "Mutual Agreement",
            title_ar: "اتفاق متبادل",
        },
        {
            title_en: "Poor Cultural Fit",
            title_ar: "عدم توافق ثقافي",
        },
        {
            title_en: "Poor Performance",
            title_ar: "أداء سيء",
        },
        {
            title_en: "Job Dissatisfaction",
            title_ar: "عدم رضا عن الوظيفة",
        },
        {
            title_en: "Personal Reasons",
            title_ar: "أسباب شخصية",
        },
        {
            title_en: "Others",
            title_ar: "أخرى",
        },
    ]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [offboardingForm, setOffboardingForm] = useState({
        title: "Off Boarding",
        service_type: "offboarding",
        description: "Off boarding",
        priority: "High",
        category: null,
        json_request: [
            {
                question_en: "User Email",
                question_ar: "بريد المستخدم",
                answer: "",
                name: "user_email",
                is_required: true,
            },
            {
                question_en: "Reason",
                question_ar: "السبب",
                answer: "",
                name: "reason",
                is_required: true,
            },
            {
                question_en: "Other service need to be closed",
                question_ar: "هل هناك خدمات أخرى يجب إغلاقها",
                answer: "",
                name: "other_service",
                is_required: false,
            },
            {
                question_en: "Note",
                question_ar: "ملاحظة",
                answer: "",
                name: "note",
                is_required: false,
            },
        ],
    });
    const [formErrors, setFormErrors] = useState("");

    const {
        data: categoriesData,
        isLoading: categoriesLoading,
    } = useQuery({
        queryKey: ["categories onboarding"],
        queryFn: () => categories.getWithFilter("tag=on_boarding").then((response) => {
            if (response.data.length !== 0) {
                setOffboardingForm({
                    ...offboardingForm,
                    category: response.data[0].id,
                });
            }
            return response.data;
        }),
        refetchOnWindowFocus: false,
    });

    const handleChanges = (value: string, name: string) => {
        setOffboardingForm({
            ...offboardingForm,
            json_request: offboardingForm.json_request.map((item) => {
                if (item.name === name) {
                    item.answer = value;
                }
                return item;
            }),
        });
    }

    const checkIfFormIsValid = () => {
        let isValid = true;
        offboardingForm.json_request.forEach((item) => {
            if (item.is_required && item.answer === "") {
                setFormErrors(language === "en" ? "Please fill all required fields" : "يرجى ملء جميع الحقول المطلوبة");
                isValid = false;
            }
        });
        return isValid;
    }

    const handleSubmit = async () => {
        if (!checkIfFormIsValid()) {
            return;
        }

        setIsSubmitting(true);
        tickets.create(offboardingForm).then((response) => {
            setIsSubmitting(false);
            navigate("/my-requests/id/" + response.data.id);
        }).catch((error) => {
            console.log("error", error);
        });
    };

    return (
        <div dir={language === "en" ? "ltr" : "rtl"}>
            {categoriesLoading && <LoadingScreen />}
            <div>
                <h1 className="text-3xl font-bold text-center m-auto my-10">
                    {language === "en" ? "User Offboarding" : "إنهاء عمل المستخدم"}
                </h1>
                <Card className="w-11/12 m-auto mt-10 lg:w-1/2">
                    <Button
                        onClick={() => navigate("/special-request/offboarding")}
                        className="my-4 w-11/12"
                        variant={"secondary"}
                    >
                        {language === "en" ? "Back" : "عودة"}
                    </Button>
                    <CardContent className="w-full text-start">
                        <h2
                            className="text-2xl font-bold text-center"
                        >
                            {language === "en" ? "User Information" : "معلومات المستخدم"}
                        </h2>
                        <div className="grid gap-4">
                            <div>
                                <Label>{language === "en" ? "User Email" : "بريد المستخدم"}</Label>  <span className="text-red-500">*</span>
                                <Input
                                    onChange={(e) => handleChanges(e.target.value, "user_email")}
                                />
                            </div>
                            <div>
                                <Label>{language === "en" ? "Reason" : "السبب"}</Label> <span className="text-red-500">*</span>
                                <Select
                                    dir={language === "en" ? "ltr" : "rtl"}
                                    onValueChange={(value) => {
                                        handleChanges(value, "reason");
                                    }}
                                >
                                    <SelectTrigger>
                                        <SelectValue
                                            placeholder={
                                                language === "en" ? "Select Reason" : "حدد السبب"
                                            }
                                        />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {listOfReasons.map((reason, index) => (
                                            <SelectGroup key={index}>
                                                <SelectItem value={reason.title_en}>
                                                    {language === "en"
                                                        ? reason.title_en
                                                        : reason.title_ar}
                                                </SelectItem>
                                            </SelectGroup>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                            <div>
                                <Label>
                                    {language === "en"
                                        ? "Other service need to be closed"
                                        : "هل هناك خدمات أخرى يجب إغلاقها"}
                                </Label>
                                <Textarea
                                    onChange={(e) => handleChanges(e.target.value, "other_service")}
                                    placeholder={language === "en" ? "ex: odoo, microsoft, etc" : "مثال: أودو، مايكروسوفت، إلخ"}
                                />
                            </div>
                            <div>
                                <Label>{language === "en" ? "Note" : "ملاحظة"}</Label>
                                <Textarea
                                    onChange={(e) => handleChanges(e.target.value, "note")}
                                />
                            </div>

                            {formErrors && (
                                <div className="text-red-500">
                                    {formErrors}
                                </div>
                            )}
                             
                            <Button
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                variant={"destructive"}
                            >
                                {isSubmitting ? (
                                    <LoadingIcon />
                                ) : language === "en" ? (
                                    "Offboard"
                                ) : (
                                    "إنهاء العمل"
                                )}
                            </Button>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default OffboardingNotRegisterUser;
