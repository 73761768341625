import React, { useEffect, useState } from 'react';
import Logo from '@/assets/Lazem-Logo.svg';
import footer from '@/assets/card-footer-image.png';
import femaleIcon from '@/assets/female-icon.png';

const EmployeeCardViewTemplate = ({
    id,
    image,
    ar_full_name,
    en_full_name,
    ar_role,
    en_role,
    gender,
}:any) => {
    const [imageUrl, setImageUrl] = useState<string | null>(null);

    useEffect(() => {
        fetchImage();
    }, [image]);

    const fetchImage = () => {
        if (!gender) {
            // If gender is not provided, use the logo as the default image
            setImageUrl(Logo);
        } else if (!image) {
            // If no image is provided, fallback based on gender
            setImageUrl(gender === "Male" ? "" : femaleIcon);
        } else if (image instanceof File || image instanceof Blob) {
            // If the image is a File or Blob, create an object URL
            setImageUrl(URL.createObjectURL(image));
        } else if (typeof image === "string") {
            // If the image is a string, assume it's a valid URL
            setImageUrl(image);
        } else {
            // For any unexpected types, log a warning and fallback to the logo
            console.warn("Unexpected image type:", image);
            setImageUrl(Logo);
        }
    };
    

    return (
        <div
            className="w-full max-w-[400px] mx-auto p-4 bg-white rounded-lg shadow-md border border-gray-200"
        >
            <div className="flex justify-center mb-4">
                <img src={Logo} alt="logo" className="w-[100px]" />
            </div>
            <div
                className="flex justify-center items-center h-[200px] border border-[#274d5b] rounded-lg mb-4 w-[210px] mx-auto"
                style={{
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}
            />
            <div className="text-center mb-4">
                <h2 className="text-xl font-bold text-[#274d5b]">{ar_full_name}</h2>
                <h2 className="text-lg font-bold text-[#ea0d44]">{en_full_name}</h2>
            </div>
            <div className="text-center text-md">
                <h3 className="text-[#274d5b]">{ar_role}</h3>
                <h3 className="text-[#ea0d44] capitalize">{en_role}</h3>
            </div>
            
            <div>
                <p className="text-center mt-4">
                    <h3 className="text-[#274d5b]">ID </h3>
                    <h3 className="text-[#ea0d44]">
                        {id}
                    </h3>
                </p>
            </div>

            <div className="mt-4">
                <img src={footer} alt="footer" />
            </div>
        </div>
    );
};

export default EmployeeCardViewTemplate;
