import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import LoadingIcon from "@/components/loading/loading-icon";
import { formTemplates } from "@/services/forms-service";
import { useQuery } from "@tanstack/react-query";
import { RadioGroupItem, RadioGroup } from "@/components/ui/radio-group";
import LoadingScreen from "@/components/loading/loading-screen";
import { Textarea } from "@/components/ui/textarea";
import { MdDeleteForever } from "react-icons/md";
import { IoCloudUploadOutline } from "react-icons/io5";
import { MultiSelect } from "@/components/ui/multi-select";
import { Camera } from "react-camera-pro";

export const CreateCustomForm = () => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState<any>(null);
  const [isReviewing, setIsReviewing] = useState(false);
  const [questionTypes, setQuestionTypes] = useState<any>([]);
  const webcamRefs = useRef<any>({});
  const [form, setForm] = useState<any>({
    title: "",
    description: "",
    form_type: "",
    is_multiple: false,
    questions: [],
  });

  const fetchQuestionTypes = async () => {
    await formTemplates
      .question_types()
      .then((response) => {
        setQuestionTypes(response);
        return response;
      })
      .catch((error) => { });
  };

  useEffect(() => {
    document.title = "Lazem GO - Create Custom Form";
  }, []);

  const { isLoading: questionTypesLoading, data: questionTypesData } = useQuery(
    {
      queryKey: ["questionTypes"],
      queryFn: fetchQuestionTypes,
      refetchOnWindowFocus: false,
    }
  );

  const { data: formTypesData } = useQuery({
    queryKey: ["formTypes"],
    queryFn: formTemplates.form_types,
    refetchOnWindowFocus: false,
  });

  const handleAddField = () => {
    setForm({
      ...form,
      questions: [
        ...form.questions,
        {
          question: "",
          question_type: "",
          is_required: false,
          description: "",
          choices: [],
          index: form.questions.length + 1,
        },
      ],
    });
  };

  const handleRemoveField = (index: number) => {
    setForm({
      ...form,
      questions: form.questions.filter((_: any, i: any) => i !== index),
    });
  };

  const handleFieldChange = (index: any, key: any, value: any) => {
    setForm({
      ...form,
      questions: form.questions.map((field: any, i: any) => {
        if (i === index) {
          if (key === "question_type") {
            return {
              ...field,
              [key]: value,
              choices:
                value === "checkbox" || value === "select" ? field.choices : [],
            };
          } else {
            return {
              ...field,
              [key]: value,
            };
          }
        }
        return field;
      }),
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setFormError(null);
    await formTemplates
      .create(form)
      .then(() => {
        navigate("/forms");
      })
      .catch((error) => { })
      .finally(() => {
        setLoading(false);
      });
  };


  const addOption = (index: any) => {
    const newForm = form.questions.map((field: any, i: any) => {
      if (
        i === index &&
        (field.question_type === "checkbox" ||
          field.question_type === "select" ||
          field.question_type === "radio" ||
          field.question_type === "multiselect")
      ) {
        return {
          ...field,
          choices: [...field.choices, ""],
        };
      }
      return field;
    });
    setForm({ ...form, questions: newForm });
  };

  const removeOption = (fieldIndex: any, optionIndex: any) => {
    const newForm = form.questions.map((field: any, i: any) => {
      if (
        i === fieldIndex &&
        (field.question_type === "checkbox" ||
          field.question_type === "select" ||
          field.question_type === "radio" ||
          field.question_type === "multiselect")
      ) {
        return {
          ...field,
          choices: field.choices.filter((_: any, j: any) => j !== optionIndex),
        };
      }
      return field;
    });
    setForm({ ...form, questions: newForm });
  };

  const handleOptionChange = (
    fieldIndex: any,
    optionIndex: any,
    value: any
  ) => {
    const newForm = form.questions.map((field: any, i: any) => {
      if (
        i === fieldIndex &&
        (field.question_type === "checkbox" ||
          field.question_type === "select" ||
          field.question_type === "radio" ||
          field.question_type === "multiselect")
      ) {
        return {
          ...field,
          choices: field.choices.map((option: any, j: any) => {
            if (j === optionIndex) {
              return value;
            }
            return option;
          }),
        };
      }
      return field;
    });
    setForm({ ...form, questions: newForm });
  };

  const handleNext = () => {
    // console.log(form);
    if (!form.title.trim()) {
      setFormError(
        language === "en" ? "Form title is required." : "عنوان النموذج مطلوب."
      );
      window.scrollTo(0, 0);
      return;
    }

    if (!form.description.trim()) {
      setFormError(
        language === "en"
          ? "Form description is required."
          : "وصف النموذج مطلوب."
      );
      window.scrollTo(0, 0);
      return;
    }

    if (!form.form_type) {
      setFormError(
        language === "en" ? "Form type is required." : "نوع النموذج مطلوب."
      );
      window.scrollTo(0, 0);
      return;
    }

    if (form.questions.length < 1) {
      setFormError(
        language === "en"
          ? "Form must have at least one question."
          : "يجب أن يحتوي النموذج على سؤال واحد على الأقل."
      );
      window.scrollTo(0, 0);
      return;
    }

    for (const field of form.questions) {
      if (!field.question.trim()) {
        setFormError(
          language === "en"
            ? "Question number " + (field.index + 1) + " is required."
            : "السؤال رقم " + (field.index + 1) + " مطلوب."
        );
        window.scrollTo(0, 0);
        return;
      }
      if (!field.question_type) {
        setFormError(
          language === "en"
            ? "Question number " + (field.index + 1) + " must have a type."
            : "يجب أن يحتوي السؤال رقم " + (field.index + 1) + " على نوع."
        );
        window.scrollTo(0, 0);
        return;
      }
      if (
        (field.question_type === "checkbox" ||
          field.question_type === "select" ||
          field.question_type === "multiselect" ||
          field.question_type === "radio") &&
        field.choices.length < 2
      ) {
        setFormError(
          language === "en"
            ? "Question number " +
            (field.index + 1) +
            " must have at least 2 choices."
            : "يجب أن يحتوي السؤال رقم " +
            (field.index + 1) +
            " على خيارين على الأقل."
        );
        window.scrollTo(0, 0);
        return;
      }

      for (const option of field.choices) {
        if (!option.trim()) {
          setFormError(
            language === "en"
              ? "All choices must be filled."
              : "يجب تعبئة جميع الخيارات."
          );
          window.scrollTo(0, 0);
          return;
        }
      }
    }

    setFormError(null);
    setIsReviewing(true);
  };

  <Button onClick={handleNext}>{language === "en" ? "Next" : "التالي"}</Button>;

  const setReviewQuestion = () => {
    return (
      <Card className="w-full md:w-1/2">
        <CardHeader>
          <Button
            onClick={() => setIsReviewing(false)}
            variant={"destructive"}
            className="flex my-4 w-fit"
          >
            {language === "en" ? "Back" : "رجوع"}
          </Button>
          <CardTitle>
            {language === "en" ? "Review Form" : "مراجعة النموذج"}
          </CardTitle>
        </CardHeader>

        <CardContent className="space-y-4 text-start">
          <Card>
            <CardHeader className="flex flex-col gap-2 text-center">
              <CardTitle>{form.title}</CardTitle>
              <CardDescription>{form.description}</CardDescription>
            </CardHeader>
            <CardContent>
              <div className="mb-4">
                {form.questions.map((field: any, index: any) => (
                  <div key={index} className="flex space-x-2 p-2 my-6 flex-col">
                    {field.question_type === "checkbox" ? (
                      <div>
                        <div>
                          <Label>
                            {field.question}{" "}
                            {field.is_required && (
                              <strong className="text-red-500">*</strong>
                            )}

                          </Label>
                          <div
                            className="flex flex-col gap-2 w-10/12 text-slate-500"
                          >
                            <Label>
                              {field.description}
                            </Label>
                          </div>
                        </div>
                        {field.choices.map((option: any, optionIndex: any) => (
                          <div
                            key={optionIndex}
                            className="flex items-center gap-2"
                          >
                            <Checkbox
                              checked={false}
                              disabled
                              className="mx-2 my-4"
                            />
                            <Label>{option}</Label>
                          </div>
                        ))}
                      </div>
                    ) : field.question_type === "camera" ? (
                      <>
                        <Label>
                          {field.question}{" "}
                          {field.is_required && (
                            <strong className="text-red-500">*</strong>
                          )}
                        </Label>
                        <div
                          className="flex flex-col gap-2 w-10/12 text-slate-500"
                        >
                          <Label>
                            {field.description}
                          </Label>
                        </div>

                        <div className="flex justify-end w-full">
                          <Button
                            onClick={() => {
                              webcamRefs.current[index].switchCamera();
                            }}
                            className="mt-4"
                            variant={"secondary"}
                          >
                            {language === "en"
                              ? "Switch Camera"
                              : "تبديل الكاميرا"}
                          </Button>
                        </div>
                        <Camera
                          ref={(ref) => (webcamRefs.current[index] = ref)}
                          aspectRatio={4 / 3}
                          errorMessages={{
                            noCameraAccessible:
                              language === "en"
                                ? "No camera available"
                                : "لا تتوفر كاميرا",
                            permissionDenied:
                              language === "en"
                                ? "Permission denied. Please refresh and give camera permission."
                                : "تم رفض الإذن. يرجى تحديث الصفحة ومنح إذن الكاميرا.",
                            switchCamera:
                              language === "en"
                                ? "It is not possible to switch camera to different one because there is only one video device accessible."
                                : "لا يمكن تبديل الكاميرا لأخرى لأن هناك جهاز فيديو واحد فقط يمكن الوصول إليه.",
                            canvas:
                              language === "en"
                                ? "Canvas is not supported."
                                : "لا يتم دعم الرسم",
                          }}
                        />
                        <Button className="mt-4" variant={"secondary"} disabled>
                          {language === "en" ? "Capture" : "التقاط"}
                        </Button>
                      </>
                    ) : field.question_type === "select" ? (
                      <div className="flex flex-col w-full gap-2 my-4">
                        <Label>
                          {field.question}{" "}
                          {field.is_required && (
                            <strong className="text-red-500">*</strong>
                          )}
                        </Label>
                        <div
                          className="flex flex-col gap-2 w-10/12 text-slate-500"
                        >
                          <Label>
                            {field.description}
                          </Label>
                        </div>
                        <Select>
                          <SelectTrigger>
                            <SelectValue placeholder={field.question} />
                          </SelectTrigger>
                          <SelectContent>
                            {field.choices.map(
                              (option: any, optionIndex: any) => (
                                <SelectItem key={optionIndex} value={option}>
                                  {option}
                                </SelectItem>
                              )
                            )}
                          </SelectContent>
                        </Select>
                      </div>
                    ) : field.question_type === "location" ? (
                      <div className="flex flex-col w-full gap-2 my-4">
                        <Label>
                          {field.question}{" "}
                          {field.is_required && (
                            <strong className="text-red-500">*</strong>
                          )}
                        </Label>
                        <div
                          className="flex flex-col gap-2 w-10/12 text-slate-500"
                        >
                          <Label>
                            {field.description}
                          </Label>
                        </div>
                        <Input disabled placeholder={field.question} />
                        <Button
                          className="flex mx-auto my-4 w-full"
                          variant={"secondary"}
                          disabled
                        >
                          {language === "en"
                            ? "Get Location"
                            : "الحصول على الموقع"}
                        </Button>
                      </div>
                    ) : field.question_type === "radio" ? (
                      <div className="flex flex-col w-full gap-2 my-4">
                        <Label>
                          {field.question}{" "}
                          {field.is_required && (
                            <strong className="text-red-500">*</strong>
                          )}
                        </Label>
                        <div
                          className="flex flex-col gap-2 w-10/12 text-slate-500"
                        >
                          <Label>
                            {field.description}
                          </Label>
                        </div>
                        <RadioGroup>
                          {field.choices.map(
                            (option: any, optionIndex: any) => (
                              <div
                                key={optionIndex}
                                className={`flex space-x-2 ${language === "en"
                                  ? "flex-row"
                                  : "flex-row-reverse"
                                  }`}
                              >
                                <RadioGroupItem
                                  value={option}
                                  id={option + "_" + field.question}
                                  className="mx-2"
                                />
                                <Label htmlFor={option + "_" + field.question}>
                                  {option}
                                </Label>
                              </div>
                            )
                          )}
                        </RadioGroup>
                      </div>
                    ) : field.question_type === "textarea" ? (
                      <div className="flex flex-col w-full gap-2 my-4">
                        <Label>
                          {field.question}{" "}
                          {field.is_required && (
                            <strong className="text-red-500">*</strong>
                          )}
                        </Label>
                        <div
                          className="flex flex-col gap-2 w-10/12 text-slate-500"
                        >
                          <Label>
                            {field.description}
                          </Label>
                        </div>
                        <Textarea disabled placeholder={field.question} />
                      </div>
                    ) : field.question_type === "file" ? (
                      <div>
                        <div className="relative my-6">
                          <div className="flex flex-col gap-2">
                            <Label>
                              {field.question}{" "}
                              {field.is_required && (
                                <strong className="text-red-500">*</strong>
                              )}
                            </Label>
                            <div
                              className="flex flex-col gap-2 w-10/12 text-slate-500"
                            >
                              <Label>
                                {field.description}
                              </Label>
                            </div>
                            <div className="relative flex cursor-pointer flex-col items-center gap-4 rounded border border-dashed border-slate-300 px-3 py-6 text-center text-sm font-medium transition-colors">
                              <input type="file" className="hidden" disabled />
                              <IoCloudUploadOutline
                                className="inline-flex h-12 items-center justify-center self-center"
                                size={24}
                              />
                              <span className="text-slate-500">
                                {language === "en"
                                  ? "Drag and drop or Browse"
                                  : "اسحب أو تصفح"}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : field.question_type === "multiselect" ? (
                      <div className="flex flex-col w-full gap-2 my-4">
                        <Label>
                          {field.question}{" "}
                          {field.is_required && (
                            <strong className="text-red-500">*</strong>
                          )}
                        </Label>
                        <div
                          className="flex flex-col gap-2 w-10/12 text-slate-500"
                        >
                          <Label>
                            {field.description}
                          </Label>
                        </div>
                        <MultiSelect
                          placeholder={field.question}
                          defaultValue={[]}
                          options={field.choices.map((option: any) => ({
                            label: option,
                            value: option,
                          }))}
                          onValueChange={(value) => {}}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col w-full gap-2 my-4">
                        <Label>
                          {field.question}{" "}
                          {field.is_required && (
                            <strong className="text-red-500">*</strong>
                          )}

                        </Label>
                        <div
                          className="flex flex-col gap-2 w-10/12 text-slate-500"
                        >
                          <Label>
                            {field.description}
                          </Label>
                        </div>
                        <Input
                          disabled
                          type={field.question_type}
                          placeholder={field.question}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        </CardContent>
        <CardFooter>
          <Button
            onClick={handleSubmit}
            disabled={loading}
            className="flex mx-auto my-4 w-full"
          >
            {loading ? (
              <LoadingIcon />
            ) : (
              <>{language === "en" ? "Create Form" : "إنشاء النموذج"}</>
            )}
          </Button>
        </CardFooter>
      </Card>
    );
  };

  return (
    <div
      className="flex flex-col items-center justify-center my-4"
      dir={language === "en" ? "ltr" : "rtl"}
    >
      {questionTypesLoading && <LoadingScreen />}
      {
        <>
          <Button
            onClick={() => navigate("/forms")}
            variant={"secondary"}
            className="flex my-4 w-full md:w-1/2"
          >
            {language === "en" ? "Back" : "عودة"}
          </Button>
          {isReviewing ? (
            setReviewQuestion()
          ) : (
            <Card className="w-full md:w-1/2">
              <CardHeader>
                <CardTitle>
                  {language === "en"
                    ? "Create Custom Form"
                    : "إنشاء نموذج مخصص"}
                </CardTitle>
              </CardHeader>
              <CardContent className="space-y-4 text-start">
                {formError && (
                  <CardDescription className="text-red-500 text-center w-full  font-semibold">
                    {formError}
                  </CardDescription>
                )}
                <div className="mb-4">
                  <Label>
                    {language === "en" ? "Form Title" : "عنوان النموذج"}
                  </Label>
                  <Input
                    value={form.title}
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                  />
                </div>
                <div className="mb-4">
                  <Label>
                    {language === "en" ? "Form Description" : "وصف النموذج"}
                  </Label>
                  <Input
                    value={form.description}
                    onChange={(e) =>
                      setForm({ ...form, description: e.target.value })
                    }
                  />
                </div>
                <div className="mb-4">
                  <Label>
                    {language === "en" ? "Form Type" : "نوع النموذج"}
                  </Label>
                  <Select
                    onValueChange={(value) =>
                      setForm({ ...form, form_type: value })
                    }
                    defaultValue={form.form_type}
                  >
                    <SelectTrigger>
                      <SelectValue>{form.form_type}</SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        {formTypesData &&
                          formTypesData.map((type: any, index: any) => (
                            <SelectItem key={index} value={type.value}>
                              {type.display_name}
                            </SelectItem>
                          ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
                {/* <div className="mb-4">
                  <Checkbox
                    onCheckedChange={(checked) =>
                      setForm({ ...form, is_multiple: checked })
                    }
                    defaultValue={form.is_multiple || false}
                  />
                  <Label className="mx-2">
                    {language === "en"
                      ? "Allow users to submit the form multiple times."
                      : "السماح للمستخدمين بتقديم النموذج أكثر من مرة."}
                  </Label>
                </div> */}

                <div className="mb-4">
                  <CardHeader>
                    <CardTitle className="text-center text-lg">
                      {language === "en" ? "Form Questions" : "أسئلة النموذج"}
                    </CardTitle>
                  </CardHeader>

                  {form.questions.map((field: any, index: any) => (
                    <Card
                      key={index}
                      className="flex space-x-2 p-2 my-6 flex-col"
                    >
                      <div className="flex justify-end w-full">
                        <Button
                          onClick={() => handleRemoveField(index)}
                          variant={"destructive"}
                        >
                          <MdDeleteForever size={24} />
                        </Button>
                      </div>
                      <div>
                        <div className="">
                          <h2 className="text-lg font-semibold !text-center">
                            {language === "en"
                              ? "Question Number"
                              : "رقم السؤال"}
                            : {field.index + 1}
                          </h2>
                        </div>
                        <Checkbox
                          className="mx-2 my-4"
                          onCheckedChange={(checked) =>
                            handleFieldChange(index, "is_required", checked)
                          }
                        />
                        <Label>
                          {language === "en"
                            ? "Required question"
                            : "سؤال إجباري"}
                        </Label>
                      </div>
                      <div className="flex flex-col w-full gap-2 my-4">
                        <Label>
                          {language === "en"
                            ? "question title"
                            : "عنوان السؤال"}
                        </Label>
                        <Input
                          value={field.question}
                          onChange={(e) =>
                            handleFieldChange(index, "question", e.target.value)
                          }
                        />
                      </div>
                      <div className="flex flex-col w-full gap-2 my-4">
                        <Label>
                          {language === "en"
                            ? "Question Description"
                            : "وصف السؤال"}
                        </Label>
                        <Textarea
                          value={field.description}
                          onChange={(e) =>
                            handleFieldChange(
                              index,
                              "description",
                              e.target.value
                            )
                          }
                        />
                      </div>
                      <div className="flex flex-col w-full gap-2 my-4">
                        <Label>
                          {language === "en" ? "Question Type" : "نوع السؤال"}
                        </Label>
                        <Select
                          onValueChange={(value) =>
                            handleFieldChange(index, "question_type", value)
                          }
                          defaultValue={field.question_type}
                        >
                          <SelectTrigger>
                            <SelectValue>{field.question_type}</SelectValue>
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {questionTypes.map((type: any, index: any) => (
                                <SelectItem key={index} value={type.value}>
                                  {type.display_name}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                      </div>
                      {(field.question_type === "checkbox" ||
                        field.question_type === "select" ||
                        field.question_type === "radio" ||
                        field.question_type === "multiselect") && (
                          <div>
                            <div></div>
                            <Label className="my-4 w-full text-lg flex justify-center">
                              {language === "en"
                                ? "Question Options: " + (field.index + 1)
                                : "خيارات السؤال: " + (field.index + 1)}
                            </Label>
                            {field.choices.map(
                              (option: any, optionIndex: any) => (
                                <div
                                  key={optionIndex}
                                  className="flex flex-wrap gap-4 "
                                >
                                  <div className="flex flex-col gap-2 mt-4 w-10/12">
                                    <Label>
                                      {language === "en"
                                        ? "Option Value"
                                        : "قيمة الخيار"}
                                    </Label>
                                    <Input
                                      value={option}
                                      onChange={(e) =>
                                        handleOptionChange(
                                          index,
                                          optionIndex,
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <div
                                    className="flex items-center justify-center cursor-pointer mt-auto mb-2"
                                    onClick={() =>
                                      removeOption(index, optionIndex)
                                    }
                                  >
                                    <MdDeleteForever
                                      size={25}
                                      className="m-auto text-red-500"
                                    />
                                  </div>
                                </div>
                              )
                            )}
                            <Button
                              onClick={() => addOption(index)}
                              className="flex  my-4"
                              variant={"secondary"}
                            >
                              {language === "en" ? "Add Option" : "إضافة خيار"}
                            </Button>
                          </div>
                        )}
                    </Card>
                  ))}
                  <Button
                    onClick={handleAddField}
                    className="flex mx-auto my-4"
                  >
                    {language === "en" ? "Add Question" : "إضافة سؤال"}
                  </Button>
                </div>
              </CardContent>
              <CardFooter>
                <Button onClick={handleNext}>
                  {language === "en" ? "Review Form" : "مراجعة النموذج"}
                </Button>
              </CardFooter>
            </Card>
          )}
        </>
      }
    </div>
  );
};

export default CreateCustomForm;
