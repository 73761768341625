const is_english = (str: string) => {
    if (str === null || str === undefined) {
        return false;
    }
    const englishRegex = /^[a-zA-Z\s\.,'"\-!@#\$%\^&\*\(\)_\+=\{\}\[\]:;<>?/\\|`~]+$/;
    return englishRegex.test(str);
}

const is_arabic = (str: string) => {
    if (str === null || str === undefined) {
        return false;
    }
    const arabicRegex = /^[\u0600-\u06FF\s\.,'"\-!@#\$%\^&\*\(\)_\+=\{\}\[\]:;<>?/\\|`~]+$/;
    return arabicRegex.test(str);
}

export {
    is_english,
    is_arabic
}
