import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/components/context/LanguageContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { format, toZonedTime } from "date-fns-tz";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
  CardDescription,
} from "@/components/ui/card";
import LoadingScreen from "@/components/loading/loading-screen";
import { useDropzone } from "react-dropzone";
import { IoCloudUploadOutline } from "react-icons/io5";
import { TiDeleteOutline } from "react-icons/ti";
import { Label } from "@/components/ui/label";
import LogFeed from "@/components/log-feed";
import { tickets, comments } from "@/services/tickets-services";
import LoadingIcon from "@/components/loading/loading-icon";
import { Separator } from "@/components/ui/separator";

const MyRequestView = () => {
  const { language } = useLanguage();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [attachment, setAttachment] = useState<any>([]);
  const [noteError, setNoteError] = useState("");
  const [isTicketUpdated, setIsTicketUpdated] = useState<any>(false);
  const [searchParams] = useSearchParams();
  const queryString = searchParams.toString();

  const {
    data: taskData,
    isLoading: taskLoading,
    isRefetching: taskRefetching,
    refetch: taskRefetch,
  } = useQuery({
    queryKey: ["My Request", id],
    queryFn: () => tickets.get_my_ticket(id),
    refetchOnWindowFocus: false,
  });


  useEffect(() => {
    document.title = "Lazem GO - My Request View";
  }, []);

  function formatDate(isoDate: any) {
    const timeZone =
      Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Riyadh";
    let formattedDateTime = format(
      toZonedTime(isoDate, timeZone),
      "MMMM d, yyyy hh:mm a",
      { timeZone }
    );
    return formattedDateTime;
  }

  const onDrop = (acceptedFiles: any) => {
    setAttachment((prevState: any[]) => [...prevState, ...acceptedFiles]);
  };
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const saveNote = async () => {
    if (!note) {
      setNoteError(language === "en" ? "Note is required" : "الملاحظة مطلوبة");
      return;
    }
    setLoading(true);
    let formData = new FormData();
    if (attachment.length > 0) {
      attachment.forEach((file: any) => {
        formData.append("attachments", file, file.name);
      });
    }
    formData.append("comment", note);
    formData.append("ticket", taskData?.data?.id);
    await comments.create(formData).then(() => {
      setNoteError("");
      window.location.reload();
    }).catch((error) => {
      console.log(error);
    });
  }


  return (
    <div dir={language === "en" ? "ltr" : "rtl"}>
      {(taskLoading) && <LoadingScreen />}

      {taskData &&
        <Card className="w-full lg:w-1/2 mx-auto mb-10">
          <CardHeader>
            <Button
              variant={"secondary"}
              onClick={() => {
                navigate("/my-requests?" + queryString);
              }}
              className="w-full my-4"
            >
              {language === "en" ? "Back" : "رجوع"}
            </Button>
            <CardTitle>{taskData?.data?.title}</CardTitle>
            <CardDescription>{taskData?.data?.description}</CardDescription>
            {taskData?.data?.service_type === "add_training_certificate" && (
              <CardDescription
                className="text-red-500 text-center font-bold text-lg"
              >
                {language === "en" ? "Please Add the logo as a note" : "يرجى إضافة الشعار كملاحظة"}
              </CardDescription>
            )}

          </CardHeader>
          <div
            className="flex justify-between items-center p-4"
          >
            <div>
              <Dialog>
                <DialogTrigger className="w-full">
                  <div className="flex justify-start my-6">
                    <Button>{language === "en" ? "Add Note" : "إضافة ملاحظة"}</Button>
                  </div>
                </DialogTrigger>
                <DialogContent dir={language === "en" ? "ltr" : "rtl"}>
                  <DialogHeader>
                    {
                      isTicketUpdated && (
                        <div
                          className="w-full text-green-500 p-2 text-center"
                        >
                          {language === "en" ? "Ticket Updated" : "تم تحديث التذكرة"}
                        </div>
                      )
                    }
                    <DialogTitle>
                      {language === "en" ? "Add Note" : "إضافة ملاحظة"}
                    </DialogTitle>
                  </DialogHeader>
                  <div className="text-start">
                    <Label>{language === "en" ? "Note" : "ملاحظة"}</Label>
                    <Textarea
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                    {/*<!-- Component: Dropzone file input --> */}
                    <div className="relative my-6">
                      <div
                        {...getRootProps({
                          className:
                            "relative flex cursor-pointer flex-col items-center gap-4 rounded border border-dashed border-slate-300 px-3 py-6 text-center text-sm font-medium transition-colors",
                        })}
                      >
                        <input {...getInputProps()} />
                        <IoCloudUploadOutline
                          className="inline-flex h-12 items-center justify-center self-center"
                          size={24}
                        />
                        <span className="text-slate-500">
                          {language === "en"
                            ? "Drag and drop or Browse"
                            : "اسحب أو تصفح"}
                        </span>
                      </div>
                    </div>
                    {attachment.length > 0 && (
                      <div>
                        {attachment.map((file: any) => (
                          <div
                            key={file.path}
                            className="flex gap-6 items-center justify-between my-2"
                          >
                            <span>{file.path}</span>
                            <TiDeleteOutline
                              onClick={() => {
                                setAttachment(
                                  attachment.filter((f: any) => f.path !== file.path)
                                );
                              }}
                              className="text-red-500 cursor-pointer"
                              size={24}
                            />
                          </div>
                        ))}
                      </div>
                    )}

                    {/*<!-- End Dropzone file input --> */}
                  </div>
                  <Button
                    onClick={saveNote}
                    className="w-full"
                    disabled={loading}
                  >
                    {loading ? <LoadingIcon /> : language === "en" ? "Save" : "حفظ"}
                  </Button>
                </DialogContent>
              </Dialog>
            </div>
          </div>
          <CardContent className="text-start">
            {
              isTicketUpdated && (
                <div
                  className="w-full bg-green-500 text-white p-2 text-center"
                >
                  {language === "en" ? "Ticket Updated" : "تم تحديث التذكرة"}
                </div>
              )
            }
            <div className="">
              <div>
                <Label>{language === "en" ? "Status" : "الحالة"}</Label>
              </div>
              <div>
                <Label
                  className={`
                              text-start w-full
                          ${taskData?.data?.status === "Open"
                      ? "text-green-500"
                      : "text-red-500"
                    }
                          `}
                >
                  {taskData?.data?.status}
                </Label>
              </div>
            </div>
            <div className="my-6">
              <Label>{language === "en" ? "Service Type" : "نوع الخدمة"}</Label>
              <div>{taskData?.data?.service_type}</div>
            </div>

            <div className="my-6">
              <Label>{language === "en" ? "Category" : "الفئة"}</Label>
              <div>{taskData?.data?.category?.name}</div>
            </div>

            <div className="my-6">
              <Label>{language === "en" ? "Assigned To" : "تعيين إلى"}</Label>
              <div>{taskData?.data?.assigned_to?.full_name}</div>
            </div>

            <div className="my-6">
              <Label>{language === "en" ? "Priority" : "الأولوية"}</Label>
              <div>{taskData?.data?.priority}</div>
            </div>

            <div
              className="flex flex-col gap-6"
            >
              <div className="my-2">
                <Label>{language === "en" ? "Requested By" : "طلب بواسطة"}</Label>
                <div>
                  {language === "en" ? taskData?.data?.created_by?.full_name : taskData?.data?.created_by?.full_name_ar || taskData?.data?.created_by?.full_name}
                </div>
              </div>
              <div className="my-2">
                <Label>{language === "en" ? "Created At" : "تاريخ الإنشاء"}</Label>
                <div>{formatDate(taskData?.data?.created_at)}</div>
              </div>
              <div className="my-2">
                <Label>{language === "en" ? "Last Updated" : "آخر تحديث"}</Label>
                <div>{formatDate(taskData?.data?.updated_at)}</div>
              </div>
            </div>
            <Separator />
            <div className="my-6">
              <Label
                className="text-start w-full text-lg font-bold"
              >
                {language === "en" ? "Ticket Information" : "معلومات التذكرة"}
              </Label>
              <div className="grid gap-4 mt-4">
                {taskData?.data?.json_request?.map((question: any, index: any) => (
                  <div key={index} className="w-full">
                    <Label
                      className="font-bold"
                    >
                      {language === "en" ? question.question_en : question.question_ar}
                    </Label>
                    <p
                      className="text-gray-600"
                      style={{ whiteSpace: 'pre-line' }}
                    >
                      {question.answer}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </CardContent>
          {taskData?.data?.comments && <LogFeed logs={taskData?.data?.comments} />}
        </Card>
      }
    </div>
  );
};

export default MyRequestView;
