import { users } from "@/services/user-service";
import { useQuery } from "@tanstack/react-query";
import { useLanguage } from "@/components/context/LanguageContext";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import LoadingScreen from "@/components/loading/loading-screen";
import { Button } from "@/components/ui/button";
import { useState } from "react";
import { Input } from "@/components/ui/input";

const OffboardingDashboard = () => {

    const { language } = useLanguage();
    const navigate = useNavigate();
    const [filteredUsers, setFilteredUsers] = useState<any>([]);
    const {
        data: usersData,
        isLoading: usersLoading,
    } = useQuery({
        queryKey: ["users"],
        queryFn: () => users.simple().then((res) => {
            setFilteredUsers(res.data);
            return res.data;
        }),
        refetchOnWindowFocus: false,
    });

    const handleSearch = (e: any) => {
        const value = e.target.value;
        const filtered = usersData?.filter((user: any) => {
            return user?.full_name?.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredUsers(filtered);
    }

    return (
        <div
        dir={language === 'en' ? 'ltr' : 'rtl'}
        >
            {usersLoading && <LoadingScreen />}
            <div>
                <h1 className="text-3xl font-bold text-center m-auto mt-10">
                    {language === 'en' ? "Select a user to offboard" : "اختر مستخدمًا لإنهاء العمل معه"}
                </h1>

                <div className="flex justify-start items-start mx-6">
                    <Button
                        onClick={() => navigate("/special-request/")}
                    >
                        {language === 'en' ? "Back" : "رجوع"}
                    </Button>
                </div>

            </div>
            <div
                className="flex flex-wrap mx-auto gap-6 p-4 justify-between"
            >
                <div>
                    <Input
                        className="w-1/2 m-auto mt-2 w-[300px]"
                        placeholder={language === 'en' ? "Search by name" : "ابحث بالاسم"}
                        onChange={(e) => handleSearch(e)}
                    />
                </div>
                <div>
                    <Button
                        className="m-auto mt-2"
                        onClick={() => navigate("/special-request/offboarding/not-in-list")}
                    >
                        {language === 'en' ? "Off Board a user that is not in the list" : "إنهاء عمل مستخدم غير موجود في القائمة"}
                    </Button>
                </div>
            </div>
            <div
                className="flex flex-wrap mx-auto gap-6 p-4 justify-content-center"
            >

                {filteredUsers && filteredUsers?.map((user: any, index: any) => (
                    <div
                        key={index}
                        className="m-auto"
                    >
                        {
                            <Card
                                className="m-3 w-60 h-40 mx-auto lg:mx-0 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105 justify-content-center"
                                onClick={() => navigate(`/special-request/offboarding/id/${user.id}`)}
                            >
                                <CardHeader
                                    className="font-bold flex m-auto text-center w-full h-full items-center justify-content-center text-center m-auto justify-center"
                                >
                                    <CardTitle
                                        className="" 
                                    >
                                       {user.full_name}
                                    </CardTitle>
                                </CardHeader>
                            </Card>
                        }
                    </div>
                ))
                }
            </div>
        </div>
    );
}

export default OffboardingDashboard;