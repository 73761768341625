import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLanguage } from "@/components/context/LanguageContext";
import { useQuery } from "@tanstack/react-query";
import LoadingScreen from "@/components/loading/loading-screen";
import LogFeed from "@/components/log-feed";
import LoadingIcon from "@/components/loading/loading-icon";
import EmployeeCardPrintTemplate from "@/components/employee-card-print-template";
import { Button } from "@/components/ui/button";
import html2canvas from "html2canvas";
import EmployeeCardViewTemplate from "@/components/employee-card-view-template";
import { card_logs, cards } from "@/services/user-service";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { is_arabic, is_english } from "@/services/language-checker";
import { categories, tickets } from "@/services/tickets-services";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const CardView = () => {
    const { language } = useLanguage();
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [card, setCard] = useState<any>();
    const [isNotifySuccess, setIsNotifySuccess] = useState(false);
    const [cardStatusList, setCardStatusList] = useState([
        { id: "need_update", display_name: "Need Update", display_name_ar: "تحتاج تحديث" },
        { id: "requested", display_name: "Requested", display_name_ar: "تم الطلب" },
        { id: "printed", display_name: "Printed", display_name_ar: "تم الطباعة" },
        { id: "delivered", display_name: "Delivered", display_name_ar: "تم التسليم" },
        { id: "expired", display_name: "Expired", display_name_ar: "منتهية" },
        { id: "ready", display_name: "Ready", display_name_ar: "جاهزة" },
    ]);
    const [selectedStatus, setSelectedStatus] = useState<any>();
    const [employeeDetail, setEmployeeDetail] = useState<any>({
        title: "Request Employee Card",
        support_type: "employee_card",
        description: "Request Employee Card for",
        priority: "High",
        json_request: [
        ],
        category: null,
    });

    const {
        data: cardsData,
        isLoading: cardsLoading,
        isFetching: cardsFetching,
        refetch: refetchCards,
    } = useQuery({
        queryKey: ["cards management"],
        queryFn: () => cards.get(id).then((res) => {
            console.log("Card Data", res.data);
            document.title = res.data?.user?.full_name + " - Card ";
            setCard(res.data);
            return res.data;
        }),
        refetchOnWindowFocus: false,
    });
    const contentRef = useRef<any>();


    const handlePrint = async () => {
        setLoading(true);
        setTimeout(async () => {
            const targetDiv = document.getElementById("component-to-print");
            if (targetDiv) {
                try {
                    const canvas = await html2canvas(targetDiv, {
                        scrollX: 0,
                        scrollY: -window.scrollY,
                        useCORS: true,
                        allowTaint: true,

                    });
                    const imageData = canvas.toDataURL("image/png");
                    const downloadLink = document.createElement("a");
                    downloadLink.href = imageData;
                    downloadLink.download = cardsData?.user?.full_name + " card.png";
                    downloadLink.click();
                } catch (error) {
                    console.error("Error converting div to image:", error);
                }
            } else {
                console.error("Target div not found");
            }
            setLoading(false);
        }, 1000);
    };

    const handleStatusChange = () => {
        setLoading(true);
        let card_log_data = {
            card: id,
            message: "Status changed to " + selectedStatus + "\n " + "تم تغيير الحالة الى " + selectedStatus,
            new_status: selectedStatus,
        }
        card_logs.create(card_log_data).then(async (res) => {
            console.log("Log created", res);
            await refetchCards();
            setLoading(false);
        }).catch((error) => {
            console.log("Error", error);
        })
    }

    const fillTheForm = () => {
        setLoading(true);
        const employeeDetailCopy = { ...employeeDetail };
        employeeDetailCopy.category = categoryData?.data[0]?.id;
        employeeDetailCopy.service_type = "print_employee_card";
        employeeDetailCopy.json_request = [
            {
                question_en: "Employee full name EN",
                question_ar: "اسم الموظف بالانجليزية",
                answer: cardsData?.user?.full_name,
                is_required: true,
                name: "employee_full_name_en",
            },
            {
                question_en: "Employee full name AR",
                question_ar: "اسم الموظف بالعربية",
                answer: cardsData?.user?.full_name_ar,
                is_required: true,
                name: "employee_full_name_ar",
            },
            {
                question_en: "Employee ID",
                question_ar: "الرقم الوظيفي",
                answer: cardsData?.employee_id,
                is_required: true,
                name: "employee_id",
            },
            {
                question_en: "Employee Position EN",
                question_ar: "منصب الموظف بالانجليزية",
                answer: cardsData?.role,
                is_required: true,
                name: "employee_position",
            },
            {
                question_en: "Employee Position AR",
                question_ar: "منصب الموظف بالعربية",
                answer: cardsData?.role_ar,
                is_required: true,
                name: "employee_position_ar",
            },
            {
                question_en: "Employee Email",
                question_ar: "بريد الموظف",
                answer: cardsData?.user?.email,
                is_required: true,
                name: "employee_email",
            },
        ];
        tickets.create(employeeDetailCopy).then((response) => {
            let card_log_data = {
                card: id,
                message: "Print request has been created Ticket ID: " + response.data.id + "\n " + "تم انشاء طلب الطباعة برقم الطلب: " + response.data.id,
                new_status: "requested",
            }
            card_logs.create(card_log_data).then(async (res) => {
                console.log("Log created", res);
                await refetchCards();
                setLoading(false);

            }).catch((error) => {
                console.log("Error", error);
            })
        }).catch((error) => {
            console.log("Error", error);
        });
    }

    const handleNotifyTheUser = () => {
        setLoading(true);
        cards.notify_employee(card?.id).then(async () => {
            // TODO: Success message
            setIsNotifySuccess(true);
            await refetchCards();
            setLoading(false);
        }).catch((error) => {
            console.log("Error", error);
            // TODO: Error handling
            setLoading(false);
            setIsNotifySuccess(false);
        });
    }
    const isReady = () => {
        return card?.status === "ready";
    }
    const {
        data: categoryData,
        isLoading: categoryLoading,
    } = useQuery({
        queryKey: ["category open ticket"],
        queryFn: async () => categories.getWithFilter("tag=print_employee_card"),
        refetchOnWindowFocus: false,
        enabled: isReady()
    });

    return (
        <div
            dir={language === "en" ? "ltr" : "rtl"}
            className="flex flex-col"
        >
            {(cardsLoading || cardsFetching) && <LoadingScreen />}

            {cardsData &&
                <>

                    <Card
                        className="mt-8 w-full md:w-4/5 lg:w-3/5 mx-auto p-2"
                    >
                        <CardHeader>
                            <Button
                                onClick={() => navigate(-1)}
                                className="w-full"
                                variant={"secondary"}
                            >
                                {language === "en" ? "Back" : "رجوع"}
                            </Button>
                        </CardHeader>
                        <EmployeeCardViewTemplate
                            id={card?.employee_id}
                            image={card?.photo}
                            ar_full_name={card?.user?.full_name}
                            en_full_name={card?.full_name_ar}
                            ar_role={card?.role_ar}
                            en_role={card?.role}
                            gender={card?.gender}
                        />

                        {
                            card?.status === "ready" &&
                            <div
                                className="p-4 flex flex-col gap-4 w-full md:w-4/5 lg:w-3/5 mx-auto"
                            >
                                <Button
                                    onClick={() => handlePrint()}
                                    disabled={loading || card?.status !== "ready"}
                                    variant={"secondary"}
                                >
                                    {language === "en" ? "View demo" : "عرض تجريبي"}
                                    {loading && <LoadingIcon />}
                                </Button>
                                <Button
                                    onClick={() => fillTheForm()}
                                    disabled={loading || card?.status !== "ready"}
                                >
                                    {language === "en" ? "Print Request" : "طلب الطباعة"}
                                    {loading && <LoadingIcon />}
                                </Button>
                            </div>
                        }
                        <div className="flex justify-center gap-4 mt-6 flex-col">
                            {card?.status === "need_update" &&

                                <>
                                    <p
                                        className="text-red-500 text-center w-full font-bold "
                                    >
                                        {language === "en" ? "You will be able to print the card after updating the information" : "سوف تتمكن من طباعة البطاقة بعد تحديث المعلومات"}
                                    </p>
                                </>
                            }
                            {
                                card?.status === "requested" &&
                                <p
                                    className="text-red-500 text-center w-full font-bold "
                                >
                                    {language === "en" ? "The card is requested and waiting for printing" : "تم طلب البطاقة وفي انتظار الطباعة"}
                                </p>
                            }
                            {
                                card?.status === "printed" &&
                                <p
                                    className="text-yellow-500 text-center w-full font-bold "
                                >
                                    {language === "en" ? "The card is printed and ready for delivery" : "تم طباعة البطاقة وجاهزة للتسليم"}
                                </p>
                            }
                            {
                                card?.status === "delivered" &&
                                <p
                                    className="text-green-500 text-center w-full font-bold "
                                >
                                    {language === "en" ? "The card is delivered" : "تم تسليم البطاقة"}
                                </p>
                            }
                            {
                                card?.status === "expired" &&
                                <p
                                    className="text-red-500 text-center w-full font-bold "
                                >
                                    {language === "en" ? "The card is expired" : "انتهت صلاحية البطاقة"}
                                </p>
                            }
                        </div>
                    </Card>

                    <div
                        className={`${loading ? "block" : "hidden"} opacity-0 fixed top-0 left-0 w-full h-full bg-black z-50`}
                    >
                        <EmployeeCardPrintTemplate
                            id={card?.employee_id}
                            ar_full_name={card?.user?.full_name}
                            en_full_name={card?.user?.full_name_ar}
                            ar_role={card?.role_ar}
                            en_role={card?.role}
                            image={card?.photo}
                            gender={card?.gender}
                            ref={contentRef}
                        />
                    </div>

                    <Card
                        dir={language === "en" ? "ltr" : "rtl"}
                        className="mt-8 w-full md:w-4/5 lg:w-3/5 mx-auto"
                    >
                        {card?.status === "need_update" &&
                            <>
                                <CardHeader>
                                    {!isNotifySuccess &&
                                        <Button
                                            onClick={handleNotifyTheUser}
                                            className="w-fit my-4"
                                            disabled={loading}
                                        >
                                            {language === "en" ? "Notify Employee" : "إخطار الموظف"}
                                            {loading && <LoadingIcon />}
                                        </Button>
                                    }
                                    {isNotifySuccess &&
                                        <p
                                            className="text-green-500 text-center w-full font-bold text-xl"
                                        >
                                            {language === "en" ? "An email has been sent to the employee" : "تم ارسال بريد الكتروني للموظف"}
                                        </p>
                                    }
                                    <CardTitle>
                                        {language === "en" ? "Information need to be updated" : "المعلومات التي تحتاج إلى تحديث"}
                                    </CardTitle>
                                </CardHeader>
                                <CardContent
                                    className="text-start"
                                >
                                    <CardDescription>
                                        {
                                            (!is_english(card?.user?.full_name)) &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "English full name" : "الاسم الكامل بالانجليزي"}
                                            </p>
                                        }
                                        {
                                            (!is_arabic(card?.user?.full_name_ar)) &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add full name in Arabic" : "اضافة الاسم الكامل بالعربي"}
                                            </p>
                                        }
                                        {
                                            !card?.role_ar &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add role in Arabic" : "اضافة الدور بالعربي"}
                                            </p>
                                        }
                                        {
                                            !card?.employee_id &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                *  {language === "en" ? "Add employee ID" : "اضافة الرقم الوظيفي"}
                                            </p>
                                        }
                                        {
                                            !card?.gender &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                * {language === "en" ? "Add employee gender" : "اضافة جنس الموظف"}
                                            </p>
                                        }
                                        {
                                            (!card?.photo && (card?.gender?.toLowerCase() === "male" || !card?.user?.gender)) &&
                                            <p
                                                className="text-xl font-bold my-2"
                                            >
                                                *  {language === "en" ? "Add employee photo" : "اضافة صورة الموظف"}
                                            </p>
                                        }

                                    </CardDescription>
                                </CardContent>
                            </>
                        }
                        <div
                            className="p-4 flex flex-col gap-4 w-full md:w-4/5 lg:w-3/5 mx-auto text-start"
                        >
                            <p
                                className="text-lg font-bold"
                            >
                                {language === "en" ? "Status" : "الحالة"}
                            </p>
                            <Select
                                onValueChange={(value) => {
                                    setSelectedStatus(value);
                                }}
                                defaultValue={card?.status}
                                dir={language === "en" ? "ltr" : "rtl"}
                            >
                                <SelectTrigger>
                                    <SelectValue placeholder={selectedStatus} />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {cardStatusList.map((status) => (
                                            <SelectItem
                                                key={status.id}
                                                value={status.id}
                                            >
                                                {language === "en" ? status.display_name : status.display_name_ar}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                            <Button
                                onClick={handleStatusChange}
                                disabled={loading || !selectedStatus}
                                className="w-full"
                            >
                                {language === "en" ? "Change Status" : "تغيير الحالة"}
                                {loading && <LoadingIcon />}
                            </Button>
                        </div>
                        <LogFeed logs={card?.employee_card_logs} />
                    </Card>
                </>
            }

        </div>
    );
};

export default CardView;