import { useLanguage } from "@/components/context/LanguageContext";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useAuth } from "@/components/context/AuthContext";

const OrganizationDashboard = () => {
  const navigate = useNavigate();
  const { checkPermission } = useAuth();
  const { language } = useLanguage();
  const [cards] = useState([
    {
      title_en: "Departments",
      title_ar: "الأقسام",
      route: "/organization/department",
      isActive: checkPermission("view_departments"),
    },
    {
      title_en: "Users",
      title_ar: "المستخدمين",
      route: "/organization/users",
      isActive: checkPermission("view_usersdashboard"),
    },
    {
      title_en: "Cards",
      title_ar: "البطاقات",
      route: "/organization/cards",
      isActive: checkPermission("view_employeecard"),
    }
  ]);

  return (
    <div
        dir={language === "en" ? "ltr" : "rtl"}
    >
        <div>
            <h1
                className="text-3xl font-bold text-center mt-10"
            >
                {language === "en" ? "Organization" : "المنظمة"}
            </h1>

            <Button
                className="m-3 flex justify-content-start items-center w-fit"
                onClick={() => navigate("/")}
                variant={"secondary"}
            >
                {language === "en" ? "Home Page" : "الصفحة الرئيسية"}
            </Button>

        </div>
        <div
        className="flex justify-content-start flex-wrap mx-auto mt-10 gap-6 p-4"
        >
        {cards.map((card, index) => (
            card?.isActive &&
            <Card
            key={index}
            className="m-auto lg:m-0 w-64 h-64 flex flex-col justify-content-center cursor-pointer transition duration-300 ease-in-out transform hover:scale-105"
            onClick={() => navigate(card.route)}
            >
            <CardHeader className="h-1/3 w-full flex justify-content-center m-auto">
                <CardTitle className="text-2xl font-bold flex justify-content-center m-auto">
                {language === "en" ? card.title_en : card.title_ar}
                </CardTitle>
            </CardHeader>
            </Card>
        ))}
        </div>
    </div>
  );
};

export default OrganizationDashboard;
