import { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import LoadingIcon from "@/components/loading/loading-icon";
import { useLanguage } from "@/components/context/LanguageContext";
import { account } from "@/services/user-service";
import { useCookies } from 'react-cookie';
import { jwtDecode } from "jwt-decode";

const LoginPage = () => {
    const { language, changeLanguage } = useLanguage();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        username: '',
        password: '',
    });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [cookies, setCookie] = useCookies(['Authorization', 'refresh']);
    const [searchParams] = useSearchParams();
    const from = searchParams.get('from') || '/';

    useEffect(() => {
        document.title = 'Lazem GO - Login';
    }, []);

    const handleChange = (e: any) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };
    const [needToActivate, setNeedToActivate] = useState(false);

    const handelSetCookie = async (data: any) => {
        let decoded = jwtDecode(data.access);
        await setCookie('Authorization', "JWT " + data.access, {
            expires: new Date((decoded.exp || 0) * 1000)
        });
        decoded = jwtDecode(data.refresh);
        await setCookie('refresh',  "JWT " + data.refresh, {
            expires: new Date((decoded.exp || 0) * 1000)
        });
        return true;
    }


    const handleLogin = async (event?: any) => {
        if (event) {
            event.preventDefault()
        }
        if (checkFields()) {
            setLoading(true);
            await account.userLogin(userInfo).then(async (res) => {
                await handelSetCookie(await res.data).then(() => {
                    window.location.href = from; // redirect to the page that user came from
                })

            }).catch((error) => {
                console.log('Error', error);
                if (error.response) {
                    let messages: any = []
                    Object.keys(error.response.data).forEach((key) => {
                        messages.push(error.response.data[key]);
                    });

                    for (let i = 0; i < messages.length; i++) {
                        if (messages[i] === 'The account is inactive') {
                            setError(language === 'en' ? 'The account is inactive' : 'الحساب غير نشط');
                            setNeedToActivate(true);
                        }
                        else if (messages[i] === 'User account is disabled.') {
                            setError(language === 'en' ? 'User account is disabled.' : 'تم تعطيل حساب المستخدم.');
                        }
                        else if (messages[i] === 'Credentials did not match') {
                            setError(language === 'en' ? 'check your email or password' : 'تحقق من بريدك الإلكتروني أو كلمة المرور');
                        }
                        else {
                            setError(messages[i]);
                        }
                    }
                }
                else {
                    setError(language === 'en' ? 'System error' : 'خطأ في النظام');
                }
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const handleResendActivation = async () => {
        setLoading(true);
        await account.userSendActivation(userInfo.username).then(() => {
            setIsSuccess(true);
            setError("");
            setNeedToActivate(false);
        }).catch((error) => {
            console.log('Error', error.response.data);
            setError(language === 'en' ? 'Error sending activation email' : 'خطأ في إرسال بريد التنشيط');
        }).finally(() => {
            setLoading(false);
        });
    }

    const checkEmail = () => {
        if (!userInfo.username.endsWith('@lazem.sa')) {
            setError(language === 'en' ? 'Please use Lazem email' : 'يرجى استخدام بريد Lazem');
            return false;
        }
        return true;
    }
    const checkFields = () => {
        if (!userInfo.username || !userInfo.password) {
            setError(language === 'en' ? 'Please fill all fields' : 'يرجى ملء جميع الحقول');
            return false;
        }
        else if (!checkEmail()) {
            return false;
        }
        return true;
    }

    return (
        <form onSubmit={handleLogin}>


            <div
                dir={language === 'en' ? 'ltr' : 'rtl'}
            >
                <Card
                    className="w-96 m-auto mt-20"
                >
                    <img src="./lazem-GO-logo.webp" alt="" className="w-3/5 mx-auto my-4" />
                    <CardHeader>
                        <div
                            className="flex justify-start"
                        >
                            <Button
                                onClick={() => changeLanguage(language === 'en' ? 'ar' : 'en')}
                                variant={"outline"}
                                type="button"
                            >
                                {language === 'en' ? 'العربية' : 'English'}
                            </Button>
                        </div>
                        <CardTitle>
                            {language === 'en' ? 'Login' : 'تسجيل الدخول'}
                        </CardTitle>
                    </CardHeader>
                    <CardContent
                        className="text-start"
                    >
                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'Email' : 'البريد الإلكتروني'}
                            </Label>
                            <Input type="email" name="username" value={userInfo.username} onChange={handleChange} />
                        </div>

                        <div
                            className="my-4"
                        >
                            <Label>
                                {language === 'en' ? 'Password' : 'كلمة المرور'}
                            </Label>
                            <Input type="password" name="password" value={userInfo.password} onChange={handleChange} />
                        </div>
                        <CardDescription
                            className="text-danger text-red-500 font-bold"
                        >
                            {error}

                        </CardDescription>
                        {
                            isSuccess && !needToActivate ?
                                <div
                                    className="text-green-500 text-center"
                                >
                                    {language === 'en' ? 'Activation email sent, please check your email' : 'تم إرسال بريد التنشيط ، يرجى التحقق من بريدك الإلكتروني'}
                                </div>
                                : ''
                        }
                    </CardContent>
                    <CardFooter
                        className="flex flex-col"
                    >
                        {needToActivate ? (
                            <Button
                                onClick={() => handleResendActivation()}
                                className="m-auto"
                                disabled={loading}
                            >
                                {loading ?
                                    <   LoadingIcon />
                                    :
                                    language === 'en' ? 'Activate Account' : 'تفعيل الحساب'
                                }
                            </Button>
                        ) :
                            (
                                <Button
                                    onClick={() => handleLogin()}
                                    disabled={loading}
                                    className="m-auto"
                                    type="submit"
                                >
                                    {loading ?
                                        <LoadingIcon />
                                        :

                                        language === 'en' ? 'Login' : 'تسجيل الدخول'
                                    }
                                </Button>
                            )
                        }

                        <div
                            className="flex justify-start w-full mt-4 text-start flex-col gap-2"
                        >
                            <div>
                                <Button
                                    onClick={() => navigate('/forgot-password')}
                                    variant={"link"}
                                    className="p-0"
                                >
                                    {language === 'en' ? 'Forgot Password?' : 'نسيت كلمة المرور؟'}
                                </Button>
                            </div>
                            <div>
                                <Label
                                    className=""
                                >
                                    {language === 'en' ? 'Don\'t have an account?' : 'ليس لديك حساب؟'}
                                </Label>
                                <Button
                                    onClick={() => navigate('/register')}
                                    variant={"link"}
                                >

                                    {language === 'en' ? 'Register' : 'تسجيل'}
                                </Button>
                            </div>
                        </div>

                    </CardFooter>
                </Card>
            </div>

        </form>
    );
};

export default LoginPage;
