import ApiUrl from "./api-url";
import axios from 'axios';
import { ApiHeaderOptions, getAuthorizationToken, getRefreshToken } from "./api-header-options";
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import { errorHandlingService } from "./error-handling-service";

const account = {
    register: async (data: any) => {
        const url = `${ApiUrl}/v1/core/users/`;
        return await axios.post(url, data).then((response) => {
            return response;
        }).catch((error: any) => {
            // errorHandlingService(error, " register", "medium");
            throw error;
        });
    },
    userSendActivation: async (email: any) => {
        const url = `${ApiUrl}/v1/auth/users/resend_activation/`;
        const frontEndUrl = process.env.FRONTEND_URL || "go.lazem.sa";
        let data = {
            "email": email,
            "website_name": "Lazem GO",
            "website_url": frontEndUrl + "/activate/?email=" + email + "&&",
        }
        return await axios.post(url, data).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " send activation", "medium");
            throw error;
        });
    },
    activateAccount: async (data: any) => {
        const url = `${ApiUrl}/v1/auth/users/activation/`;
        return await axios.post(url, data).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " activate account", "medium");
            throw error;
        });
    },
    userLogin: async (data: any) => {
        const url = `${ApiUrl}/v1/auth/jwt/create/`;
        return await axios.post(url, data).then((response) => {
            return response;
        }).catch((error: any) => {
            throw error;
        });
    },
    resetPassword: async (data: any) => {
        const url = `${ApiUrl}/v1/auth/users/reset_password/`;
        return await axios.post(url, data).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " reset password", "medium");
            throw error;
        });
    },
    submitNewPassword: async (data: any) => {
        const url = `${ApiUrl}/v1/auth/users/reset_password_confirm/`;
        return await axios.post(url, data).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " submit new password", "medium");
            throw error;
        });
    }
}


const getMyProfile = async () => {
    const url = `${ApiUrl}/v1/core/users/profile/`;
    return await axios.get(url, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error: any) => {
        errorHandlingService(error, " get my profile", "high");
        throw error;
    });
}


const updateProfile = async (data: any) => {
    const url = `${ApiUrl}/v1/core/profile/`;
    return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
        return response;
    }).catch((error: any) => {
        errorHandlingService(error, " update profile", "medium");
        throw error;
    });
}


const handelSetCookie = async (data: any) => {
    let decoded = jwtDecode(data.access);
    Cookies.set('Authorization', "JWT " + data.access, {
        expires: new Date((decoded.exp || 0) * 1000)
    })
}

const users = {
    me: async () => {
        const url = `${ApiUrl}/v1/core/users/me/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " me", "high");
            throw error;
        });
    },
    simple_me: async () => {
        const url = `${ApiUrl}/v1/core/users/simple_me/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " simple me", "high");
            throw error;
        });
    },
    update_me: async (data: any) => {
        /*
        example_data = {
            "profile_user": {
                "phone_number": "05555555",
                "address": "Riyadh",
                "gender": "Male"
            },
            "staff_profile": {
                "department": "01fbb8d8-8ca6-4865-96d6-0fc52cfce224",
                "role": "ae3e28af-612e-490f-b565-45f07474d228"
            },
            "middle_name": "Bakheet",
            "last_name": "Bashah"
        }
        */
        const url = `${ApiUrl}/v1/core/users/me/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " update me", "medium");
            throw error;
        });
    },
    update_profile_photo: async (data: any) => {
        const url = `${ApiUrl}/v1/core/staff_profiles/me/`;
        return await axios.patch(url, data, await ApiHeaderOptions(undefined)).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " update profile photo", "medium");
            throw error;
        });
    },
    list: async () => {
        const url = `${ApiUrl}/v1/core/users/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " list users", "high");
            throw error;
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/users/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " get user", "high");
        });
    },
    profiles: async () => {
        const url = `${ApiUrl}/v1/core/users/profiles/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " profiles", "high");
            throw error;
        });
    },
    simple: async () => {
        // Get simple user data [id, full_name, email]
        // This is useful for getting a list of users in a dropdown/select input field
        const url = `${ApiUrl}/v1/core/users/simple/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " simple users", "high");
            throw error;
        });
    },
    update: async (id: any, data: any) => {
        /*
        example_data = {
            "profile_user": {
                "phone_number": "05555555",
                "address": "Riyadh",
                "gender": "Male"
            },
            "staff_profile": {
                "department": "01fbb8d8-8ca6-4865-96d6-0fc52cfce224",
                "role": "ae3e28af-612e-490f-b565-45f07474d228"
            },
            "middle_name": "Bakheet",
            "last_name": "Bashah"
        }
        */
        const url = `${ApiUrl}/v1/core/users/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " update user", "medium");
            throw error;
        });
    },
    refreshToken: async () => {
        const url = `${ApiUrl}/v1/auth/jwt/refresh/`;
        let token = getRefreshToken();
        if (!token || token === '') {
            return false;
        }
        let refresh = token.split(' ')[1];
        let data = {
            "refresh": refresh
        }
        return await axios.post(url, data).then(async (response) => {
            await handelSetCookie(response.data);
            return response.data;
        }).catch(async (error: any) => {
            Cookies.remove('refresh');
            Cookies.remove('Authorization');
            throw error;
        });
    },
    verifyToken: async () => {
        const url = `${ApiUrl}/v1/auth/jwt/verify/`;
        return await axios.post(url, {
            token: getAuthorizationToken()
        }).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " verify token", "high");
            // Delete the token from the cookies
            Cookies.remove('Authorization');
            throw error;
        });
    }
}

const cards = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/employee_card/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " list cards", "high");
            throw error;
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/employee_card/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " get card", "high");
            throw error;
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/core/employee_card/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " create card", "medium");
            throw error;
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/core/employee_card/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " update card", "medium");
            throw error;
        });
    },
    notify_employee: async (id: any) => {
        const url = `${ApiUrl}/v1/core/employee_card/notify_employee_to_update_profile/`;
        const data = {
            "id": id
        }
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " notify employee", "medium");
            throw error;
        });
    }
}

const card_logs = {
    create: async (data: any) => {
        /*
        data = {
            "card": "6ecfe547-018f-49a5-a2a0-e9e81a0fa285",
            "message": "Print request has been created",
            "new_status":"requested"
        }
        */
        const url = `${ApiUrl}/v1/core/employee_card_logs/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " create card log", "medium");
            throw error;
        });
    }
}

const roles = {
    list: async () => {
        const url = `${ApiUrl}/v1/core/roles/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " list roles", "high");
            throw error;
        });
    },
    get: async (id: any) => {
        const url = `${ApiUrl}/v1/core/roles/${id}/`;
        return await axios.get(url, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " get role", "high");
            throw error;
        });
    },
    create: async (data: any) => {
        const url = `${ApiUrl}/v1/core/roles/`;
        return await axios.post(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " create role", "medium");
            throw error;
        });
    },
    update: async (id: any, data: any) => {
        const url = `${ApiUrl}/v1/core/roles/${id}/`;
        return await axios.patch(url, data, await ApiHeaderOptions()).then((response) => {
            return response;
        }).catch((error: any) => {
            errorHandlingService(error, " update role", "medium");
            throw error;
        });
    },
}
export { getMyProfile, updateProfile, users, roles, cards, account, card_logs };