import { useNavigate } from "react-router-dom";
import { useLanguage } from "../context/LanguageContext";
import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label"
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group"
import { 
    CardContent,
    CardFooter,
 } from "../ui/card";
import { Button } from "../ui/button";
import LoadingIcon from "../loading/loading-icon";
import { getTaskOptions, editTask } from "@/services/task-services";
import LoadingScreen from "../loading/loading-screen";
import { useQuery } from "@tanstack/react-query";

const TaskTypeConsent = ({id}:any) => {

    const { language } = useLanguage();
    const navigate = useNavigate();
    const [answer, setAnswer] = useState('');
    const [error, setError] = useState('');
    const [formLoading, setFormLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [taskOptions, setTaskOptions] = useState<any>();

    useEffect(() => {
        // fetchTaskOptions();
    }, []);

    const fetchTaskOptions = async () => {
        setPageLoading(true);
        await getTaskOptions(id).then((response:any) => {
            setTaskOptions(response.data.actions.PUT.approval_response.choices);
        }).catch((error) => {
            console.log(error);
            setError(error.message);
        }).finally(() => {
            setPageLoading(false);
        });
    }; 
    
    const { data: taskOptionsData } = useQuery({
        queryKey: ['taskOptions', id],
        queryFn: fetchTaskOptions,
        enabled: !!id,
        refetchOnWindowFocus: false,
    });

    const handelSubmit = () => {
        if(answer !== ''){
            setFormLoading(true);
            editTask(id, {approval_response: answer}).then((response) => {
                navigate('/tasks');
            }).catch((error) => {
                console.log(error);
                setError(error.message);
            }).finally(() => {
                setFormLoading(false);
            });
        }
        else{
            setError(language === 'en' ? 'Please select an answer' : 'يرجى تحديد إجابة');
        }
    }


    return (
        <div
            dir={language === 'en' ? 'ltr' : 'rtl'}
        >
            <>
                {pageLoading && <LoadingScreen />}
                <div
                >
                    <CardContent
                        className="flex flex-col space-y-4 mt-10"
                    >
                        <RadioGroup
                            onValueChange = {(value) => setAnswer(value)}
                            className="flex flex-col space-y-4 justify-start text-start "
                        >
                            {
                                taskOptions && taskOptions.map((option:any) => (
                                    <div
                                        key={option.value}
                                        className={`flex space-x-2 ${language === 'en' ? 'flex-row' : 'flex-row-reverse'}`}
                                    >
                                        <RadioGroupItem 
                                            value={option.value} 
                                            id={option.value}
                                            className="mx-2"
                                        />
                                        <Label htmlFor={option.value}>
                                            {option.display_name}
                                        </Label>
                                    </div>
                                ))
                            }
                        </RadioGroup>

                    </CardContent>
                    <CardFooter
                        className="flex flex-col items-center justify-center w-full gap-4"
                    >
                        <Button
                            className="w-fit mx-auto"
                            onClick={handelSubmit}
                            disabled={formLoading}
                        >
                            {formLoading ? <LoadingIcon /> : language === 'en' ? 'Submit' : 'إرسال'}
                        </Button>
                        {error !== '' && <p className="text-red-500 text-sm">{error}</p>}
                    </CardFooter>
                </div>
            </>
        </div>
    );
};

export default TaskTypeConsent;